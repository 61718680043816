import { type LineItem } from '@amici/myamici-api-client'
import { type ComponentProps, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationCircle } from 'react-icons/bs'
import classNames from 'classnames'
import MaTooltip from '../../common/components/MaTooltip'
import styles from '../assets/scss/OrderLineItemTooltip.module.scss'

function OrderLineItemWarningTooltip ({
  item,
  className
}: Readonly<{ item: LineItem } & ComponentProps<'span'>>): ReactNode {
  const { t } = useTranslation()

  const issues: string =
    item.issues
      ?.map(issue =>
        t(`order.items.issue.${issue}`, {
          on_hold_date: item.on_hold_date ? new Date(item.on_hold_date) : '',
          formatParams: {
            on_hold_date: {
              day: '2-digit',
              month: 'short',
              year: 'numeric'
            }
          }
        })
      )
      .join(', ') ?? ''

  if (!issues && !item.amici_notes?.trim()) {
    return null
  }

  return (
    <MaTooltip
      placement={'top'}
      trigger={['hover', 'click']}
      text={
        <div className={styles.text}>
          {issues && (
            <p className={!item.amici_notes ? 'text-center' : undefined}>
              {issues}
            </p>
          )}
          {item.amici_notes && <p>{item.amici_notes}</p>}
        </div>
      }
      className={classNames(styles.trigger, className)}
    >
      <BsExclamationCircle size={16} role="button" />
    </MaTooltip>
  )
}

export default OrderLineItemWarningTooltip
