import MaPageTitle from '../../common/components/MaPageTitle'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useWorkflows from '../hooks/useWorkflows'
import { type ReactElement } from 'react'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import {
  BaseStateTypeEnum,
  type BaseWorkflow,
  ConditionType,
  FieldType,
  NumericFieldOperator,
  RelationType,
  WorkflowOutcome
} from '@amici/myamici-workflow-client'
import WorkflowEditor from '../components/WorkflowEditor'

const { ACTION, START, CONDITION, END } = BaseStateTypeEnum
const { GREATER_THAN } = NumericFieldOperator

const defaultWorkflow: BaseWorkflow = {
  name: 'Approval Workflow',
  relations: [RelationType.ORDER_REQUEST],
  states: [
    {
      name: 'Submitted for approval',
      type: START
    },
    {
      name: 'Value Check',
      type: CONDITION,
      conditions: [
        {
          type: ConditionType.NUMERIC_FIELD,
          field_type: FieldType.ORDER_REQUEST_VALUE,
          operator: GREATER_THAN,
          value: 100
        }
      ]
    },
    {
      name: 'High Value Approval',
      type: ACTION,
      assignment_group: ''
    },
    {
      name: 'Approval',
      type: ACTION,
      assignment_group: ''
    },
    {
      name: 'Fully Approved',
      type: END,
      outcome: WorkflowOutcome.SUCCESS
    },
    {
      name: 'Rejected',
      type: END,
      outcome: WorkflowOutcome.FAILURE
    }
  ],
  transitions: [
    {
      name: 'Submitted',
      source: 'Submitted for approval',
      target: 'Value Check'
    },
    {
      name: 'High Value',
      source: 'Value Check',
      target: 'High Value Approval'
    },
    {
      name: 'Low Value',
      source: 'Value Check',
      target: 'Approval'
    },
    {
      name: 'Approve',
      source: 'High Value Approval',
      target: 'Fully Approved'
    },
    {
      name: 'Reject',
      source: 'High Value Approval',
      target: 'Rejected'
    },
    {
      name: 'Approve',
      source: 'Approval',
      target: 'Fully Approved'
    },
    {
      name: 'Reject',
      source: 'Approval',
      target: 'Rejected'
    }
  ]
}

function Workflows (): ReactElement {
  const { t } = useTranslation()
  const { workflow: existingWorkflow, error } = useWorkflows(
    RelationType.ORDER_REQUEST
  )

  const workflow = error ? defaultWorkflow : existingWorkflow

  return (
    <Container fluid="auto" className="ma-page">
      <MaPageTitle>{t('workflows.title')}</MaPageTitle>
      {workflow ? <WorkflowEditor workflow={workflow} /> : <LoadingSpinner />}
    </Container>
  )
}

export default Workflows
