import useApi from '../../common/hooks/useApi'
import useSWR from 'swr'
import {
  type Workflow,
  type WorkflowsApiUpdateRequest,
  type BaseWorkflow,
  type WorkflowsApiCreateRequest,
  type RelationType
} from '@amici/myamici-workflow-client'
import useAccounts from '../../common/hooks/useAccounts'
import useSWRMutation from 'swr/mutation'

interface UseWorkflowsHook {
  workflow: Workflow | undefined
  error: Error | undefined
  update: (workflow: Workflow) => Promise<Workflow>
  create: (workflow: BaseWorkflow) => Promise<Workflow>
  isSaving: boolean
  isLoading: boolean
}

function useWorkflows (relation: RelationType): UseWorkflowsHook {
  const {
    workflowsApi: { get, update, create },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const {
    data: workflow,
    error,
    isLoading
  } = useSWR<Workflow, Error>(
    accountId ? ['workflows', accountId] : null,
    async () =>
      await fetcher(get, {
        accountId,
        relation
      })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation<
    Workflow,
    Error,
    ['workflows', string] | null,
    WorkflowsApiUpdateRequest
  >(
    accountId ? ['workflows', accountId] : null,
    async (_, { arg }) => await fetcher(update, arg),
    { populateCache: true, revalidate: false }
  )

  const updateWorkflow = async (workflow: Workflow): Promise<Workflow> =>
    await triggerUpdate({
      workflowId: workflow.id,
      workflowUpdate: workflow,
      accountId
    })

  const { trigger: triggerCreate, isMutating: isCreating } = useSWRMutation<
    Workflow,
    Error,
    ['workflows', string] | null,
    WorkflowsApiCreateRequest
  >(
    accountId ? ['workflows', accountId] : null,
    async (_, { arg }) => await fetcher(create, arg),
    { populateCache: true, revalidate: false }
  )

  const createWorkflow = async (workflow: BaseWorkflow): Promise<Workflow> =>
    await triggerCreate({
      baseWorkflow: workflow,
      accountId
    })

  return {
    workflow,
    error,
    update: updateWorkflow,
    create: createWorkflow,
    isSaving: isUpdating || isCreating,
    isLoading
  }
}

export default useWorkflows
