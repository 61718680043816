import { useContext } from 'react'
import { type Task, TaskType } from '../models/Task'
import ReactGA from 'react-ga4'
import { SortDirection } from '../../common/types/sort-direction'
import {
  QuickAccessContext,
  QuickAccessDispatchContext
} from '../../quick-access/components/QuickAccessContextProvider'
import { QuickAccessAction } from '../../quick-access/hooks/useQuickAccessState'
import usePurchaseOrderTasks from './usePurchaseOrderTasks'
import useWorkflowTasksList from '../../workflow/hooks/useWorkflowTasksList'

interface UseTasksHook {
  tasks: Task[]
  search: string
  isLoading: boolean
  totalTasks: number
  sortingOrder: SortDirection
  changeSortingOrder: (sortingOrder: SortDirection) => void
  changeSearch: (search: string) => void
}

function useTasks (): UseTasksHook {
  const quickAccessState = useContext(QuickAccessContext)
  const quickAccessDispatch = useContext(QuickAccessDispatchContext)

  const {
    tasks: purchaseOrderTasks,
    isLoading: purchaseOrderTasksLoading,
    filter: purchaseOrderTasksFilter
  } = usePurchaseOrderTasks()
  const { tasks: workflowTasks, filter: workflowTasksFilter } =
    useWorkflowTasksList()

  const mappedPurchaseOrderTasks = (purchaseOrderTasks ?? []).map(value => ({
    ...value,
    created: value.created_date,
    task_type: TaskType.PURCHASE_ORDER
  }))
  const mappedWorkflowTasks = (workflowTasks ?? []).map(value => ({
    ...value,
    task_type: TaskType.WORKFLOW
  }))
  const tasks: Task[] = [...mappedPurchaseOrderTasks, ...mappedWorkflowTasks]

  let filteredTasks = tasks
  if (quickAccessState.tasksSearch) {
    filteredTasks = [
      ...mappedPurchaseOrderTasks.filter(value =>
        purchaseOrderTasksFilter(quickAccessState.tasksSearch, value)
      ),
      ...mappedWorkflowTasks.filter(value =>
        workflowTasksFilter(quickAccessState.tasksSearch, value)
      )
    ]
  }

  filteredTasks.sort((a: Task, b: Task): number => {
    if (a.created === b.created) return 0

    if (quickAccessState.tasksSortingOrder === SortDirection.ASC) {
      return a.created > b.created ? 1 : -1
    }

    return a.created > b.created ? -1 : 1
  })

  const changeSortingOrder = (sortingOrder: SortDirection): void => {
    ReactGA.event('sort_item_list', {
      item_list_id: 'tasks',
      sort_field: 'created_date',
      sort_order: sortingOrder
    })
    quickAccessDispatch({
      type: QuickAccessAction.CHANGE_TASKS_SORTING_ORDER,
      value: sortingOrder
    })
  }

  const changeSearch = (filter: string): void => {
    ReactGA.event('search_item_list', {
      item_list_id: 'tasks',
      search_term: 'tasks:' + filter
    })
    quickAccessDispatch({
      type: QuickAccessAction.CHANGE_TASKS_SEARCH,
      value: filter
    })
  }

  return {
    totalTasks: tasks.length,
    tasks: filteredTasks,
    search: quickAccessState.tasksSearch,
    sortingOrder: quickAccessState.tasksSortingOrder,
    changeSortingOrder,
    changeSearch,
    isLoading: purchaseOrderTasksLoading
  }
}

export default useTasks
