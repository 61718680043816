import type { ReactElement } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import {
  type OrderRequestLineItem,
  type OrderRequestSupplierOrderMethodEnum,
  ProductStatusEnum,
  type Supplier,
  type SupplierResource
} from '@amici/myamici-api-client'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import MaActionIcon from '../../common/components/MaActionIcon'
import {
  BsExclamationCircle,
  BsExclamationTriangle,
  BsPencil,
  BsXLg
} from 'react-icons/bs'
import styles from '../assets/scss/OrderRequestSpendTable.module.scss'
import { Link } from 'react-router-dom'
import { useUpdatingLineItemsState } from './OrderRequestUpdatingLineItemsProvider'
import MaTooltip from '../../common/components/MaTooltip'
import classNames from 'classnames'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import useOrderRequestSuppliers from '../hooks/useOrderRequestSuppliers'

function TableHead ({
  includeActions,
  selectable
}: Readonly<{
  includeActions: boolean
  selectable: boolean
}>): ReactElement {
  return (
    <thead>
      <tr>
        {selectable && <th scope="col"></th>}
        <th scope="col">
          <Trans i18nKey="order_request.spend.table.column.product" />
        </th>
        <th scope="col">
          <Trans i18nKey="order_request.spend.table.column.pack_size" />
        </th>
        <th scope="col" className={styles.quantity}>
          <Trans i18nKey="order_request.spend.table.column.quantity" />
        </th>
        <th scope="col" className={styles.price}>
          <Trans i18nKey="order_request.spend.table.column.price" />
        </th>
        <th scope="col" className={styles.subtotal}>
          <Trans i18nKey="order_request.spend.table.column.subtotal" />
        </th>
        <th scope="col" className={styles['spend-categories']}>
          <Trans i18nKey="order_request.spend.table.column.spend_categories" />
        </th>
        {includeActions && (
          <th scope="col" className={styles.actions}>
            <Trans i18nKey="order_request.spend.table.column.actions" />
          </th>
        )}
      </tr>
    </thead>
  )
}

function TableFooter ({
  totalItemCount,
  total,
  currency,
  includeActions,
  hideTotals,
  selectable
}: Readonly<{
  totalItemCount: number
  total: number
  currency: string
  includeActions: boolean
  hideTotals: boolean
  selectable: boolean
}>): ReactElement {
  return (
    <tfoot>
      <tr>
        {selectable && <td />}
        <td />
        <td>
          <Trans i18nKey="order_request.spend.table.summary.totals" />
        </td>
        <td className={styles.items}>
          <Trans
            i18nKey="order_request.spend.table.summary.items"
            values={{
              count: totalItemCount
            }}
          />
        </td>
        <td colSpan={2} className={styles.total}>
          {hideTotals ? (
            '-'
          ) : (
            <Trans
              i18nKey="common.price"
              values={{
                price: total,
                currency
              }}
            />
          )}
        </td>
        <td />

        {includeActions && <td />}
      </tr>
    </tfoot>
  )
}

function LineItemRow ({
  orderRequestLineItem,
  onEdit,
  onRemove,
  includeActions,
  updatesRequired,
  selected,
  onSelectedChange,
  selectable
}: Readonly<{
  orderRequestLineItem: OrderRequestLineItem
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
  updatesRequired: boolean
  selected: boolean
  onSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
  selectable: boolean
}>): ReactElement {
  const { t } = useTranslation()

  const lineItem = orderRequestLineItem.line_item
  const productPrice = orderRequestLineItem.product_price
  const state = useUpdatingLineItemsState()
  const pendingEdit = state?.activeEditingIds.includes(
    orderRequestLineItem.line_item.id
  )
  const isProductInactive =
    lineItem.product.status === ProductStatusEnum.INACTIVE

  return (
    <tr key={lineItem.id}>
      {selectable && (
        <td className={styles.select}>
          <MaCheckbox
            aria-label={lineItem.product.part_number ?? ''}
            checked={selected}
            onCheckedChange={checked => {
              onSelectedChange(orderRequestLineItem, checked)
            }}
            disabled={isProductInactive}
          />
        </td>
      )}
      <td
        className={classNames(
          styles.product,
          isProductInactive ? styles.inactive : undefined
        )}
      >
        {updatesRequired && (
          <MaTooltip
            text={t('order_request.updates_required.tooltip')}
            trigger={['hover', 'focus']}
          >
            {isProductInactive ? (
              <BsExclamationCircle className={styles['danger-icon']} />
            ) : (
              <BsExclamationTriangle className={styles['warning-icon']} />
            )}
          </MaTooltip>
        )}
        <Link to={`/purchasing/products/${lineItem.product.id}`}>
          {lineItem.product.description}
        </Link>
      </td>
      <td
        className={classNames(
          styles['pack-size'],
          isProductInactive ? styles.inactive : undefined
        )}
      >
        <Trans
          i18nKey="common.product.pack_size_value"
          values={{
            count: lineItem.product.no_of_units ?? 1,
            packSizeValue: lineItem.product.pack_size,
            packSizeUnit: t(`units.${lineItem.product.pack_size_unit}`, {
              count: lineItem.product.pack_size
            })
          }}
        />
      </td>
      <td
        className={classNames(
          styles.quantity,
          isProductInactive ? styles.inactive : undefined
        )}
      >
        {lineItem.quantity}
      </td>
      <td className={styles.price}>
        {isProductInactive ? (
          '-'
        ) : (
          <Trans
            i18nKey="common.price"
            values={{
              price: productPrice,
              currency: lineItem.currency
            }}
          />
        )}
      </td>
      <td className={styles.subtotal}>
        {isProductInactive ? (
          '-'
        ) : (
          <Trans
            i18nKey="common.price"
            values={{
              price: lineItem.quantity * productPrice,
              currency: lineItem.currency
            }}
          />
        )}
      </td>
      <td className={styles['spend-categories']}>
        {lineItem.spend_categories?.map(sc => sc.code).join(', ')}
      </td>
      {includeActions && (
        <td className={styles.actions}>
          <div>
            {pendingEdit ? (
              <Spinner role="status" aria-busy="true" size="sm" />
            ) : (
              <MaActionIcon
                title={t('order_request.action.line_item.edit')}
                onClick={() => {
                  onEdit(orderRequestLineItem)
                }}
                disabled={isProductInactive}
              >
                <BsPencil size={16} />
              </MaActionIcon>
            )}
            <MaActionIcon
              className={styles.remove}
              title={t('order_request.action.line_item.remove')}
              onClick={() => {
                onRemove(orderRequestLineItem)
              }}
            >
              <BsXLg size={16} />
            </MaActionIcon>
          </div>
        </td>
      )}
    </tr>
  )
}

function SupplierRows ({
  supplier,
  supplierItems,
  itemsRequiringUpdates,
  minOrderCharge,
  deliveryCharge,
  currency,
  onEdit,
  onRemove,
  includeActions,
  selectedSuppliers,
  selectedLineItemIds,
  onItemSelectedChange,
  onToggleSelectionBySupplier,
  orderMethod,
  selectable
}: Readonly<{
  supplier: SupplierResource
  supplierItems: OrderRequestLineItem[]
  itemsRequiringUpdates: OrderRequestLineItem[]
  minOrderCharge: number
  deliveryCharge: number
  currency: string
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
  selectedSuppliers: Map<string, CheckedState>
  selectedLineItemIds: string[]
  onItemSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
  onToggleSelectionBySupplier: (
    supplier: Supplier,
    checked: CheckedState
  ) => void
  orderMethod?: OrderRequestSupplierOrderMethodEnum
  selectable: boolean
}>): ReactElement[] {
  const supplierLineItemsInactive = supplierItems.every(
    lineItem => lineItem.line_item.product.status === ProductStatusEnum.INACTIVE
  )

  const supplierRows = [
    <tr
      className={styles['supplier-summary']}
      key={`supplier-summary-${supplier.id}`}
    >
      {selectable && (
        <td className={styles.select}>
          <MaCheckbox
            aria-label={supplier.name ?? ''}
            checked={selectedSuppliers.get(supplier.id ?? '')}
            onCheckedChange={checked => {
              onToggleSelectionBySupplier(supplier, checked)
            }}
            disabled={supplierLineItemsInactive}
          />
        </td>
      )}
      <td className={styles.name} colSpan={7}>
        <>
          <span>{supplier.name}</span>
          <span>
            <Trans i18nKey="order_request.order_method.title" /> -{' '}
            <Trans
              i18nKey="order_request.order_method"
              values={{ context: orderMethod }}
            />
          </span>
        </>
      </td>
    </tr>
  ]

  const lineItemRows = supplierItems.map(orderRequestLineItem => (
    <LineItemRow
      key={orderRequestLineItem.line_item.id}
      orderRequestLineItem={orderRequestLineItem}
      onEdit={onEdit}
      onRemove={onRemove}
      includeActions={includeActions}
      updatesRequired={
        itemsRequiringUpdates.includes(orderRequestLineItem) ||
        orderRequestLineItem.line_item.product.status ===
          ProductStatusEnum.INACTIVE
      }
      selected={selectedLineItemIds.includes(orderRequestLineItem.line_item.id)}
      onSelectedChange={onItemSelectedChange}
      selectable={selectable}
    />
  ))

  supplierRows.push(...lineItemRows)

  if (minOrderCharge > 0) {
    const price = (
      <Trans
        i18nKey="common.price"
        values={{
          price: minOrderCharge,
          currency
        }}
      />
    )
    supplierRows.push(
      <tr key={`${supplier.id}-min_order_charge`}>
        {selectable && <td />}
        <td>
          <Trans i18nKey="order_request.spend.min_order_charge" />
        </td>
        <td>-</td>
        <td className={styles.quantity}>-</td>
        <td className={styles.price}>
          {supplierLineItemsInactive ? '-' : price}
        </td>
        <td className={styles.subtotal}>
          {supplierLineItemsInactive ? '-' : price}
        </td>
        <td />
        {includeActions && <td />}
      </tr>
    )
  }
  if (deliveryCharge > 0) {
    const price = (
      <Trans
        i18nKey="common.price"
        values={{
          price: deliveryCharge,
          currency
        }}
      />
    )
    supplierRows.push(
      <tr key={`${supplier.id}-est_delivery`}>
        {selectable && <td />}
        <td>
          <Trans i18nKey="order_request.spend.est_delivery" />
        </td>
        <td>-</td>
        <td className={styles.quantity}>-</td>
        <td className={styles.price}>
          {supplierLineItemsInactive ? '-' : price}
        </td>
        <td className={styles.subtotal}>
          {supplierLineItemsInactive ? '-' : price}
        </td>
        <td />
        {includeActions && <td />}
      </tr>
    )
  }
  return supplierRows
}

export interface OrderRequestSpentTableProps {
  orderRequestId: string
  orderRequestLineItems: OrderRequestLineItem[]
  itemsRequiringUpdates: OrderRequestLineItem[]
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
  selectable: boolean
  selectedLineItemIds: string[]
  selectedSuppliers: Map<string, CheckedState>
  onItemSelectedChange: (
    lineItem: OrderRequestLineItem,
    checked: CheckedState
  ) => void
  onToggleSelectionBySupplier: (
    supplier: Supplier,
    checked: CheckedState
  ) => void
}

function OrderRequestSpendTable ({
  orderRequestId,
  orderRequestLineItems,
  itemsRequiringUpdates,
  onEdit,
  onRemove,
  includeActions,
  selectable,
  selectedLineItemIds,
  selectedSuppliers,
  onItemSelectedChange,
  onToggleSelectionBySupplier
}: Readonly<OrderRequestSpentTableProps>): ReactElement {
  const currency = orderRequestLineItems[0]?.line_item.currency ?? 'GBP'
  const {
    suppliers,
    getSupplierItems,
    getTotal,
    getTotalItemCount,
    getSupplierMinOrderCharge,
    getSupplierDeliveryCharge
  } = useOrderRequestSummary(orderRequestLineItems)
  const { getOrderMethod } = useOrderRequestSuppliers(orderRequestId)

  return (
    <div className={styles['order-request-spend-table']}>
      <Table responsive bordered>
        <TableHead includeActions={includeActions} selectable={selectable} />
        <tbody>
          {suppliers.map(supplier => (
            <SupplierRows
              key={supplier.id}
              supplier={supplier}
              supplierItems={getSupplierItems(supplier, currency)}
              itemsRequiringUpdates={itemsRequiringUpdates}
              minOrderCharge={getSupplierMinOrderCharge(supplier, currency)}
              deliveryCharge={getSupplierDeliveryCharge(supplier, currency)}
              currency={currency}
              onEdit={onEdit}
              onRemove={onRemove}
              includeActions={includeActions}
              selectedSuppliers={selectedSuppliers}
              selectedLineItemIds={selectedLineItemIds}
              onItemSelectedChange={onItemSelectedChange}
              onToggleSelectionBySupplier={onToggleSelectionBySupplier}
              orderMethod={getOrderMethod(supplier)}
              selectable={selectable}
            />
          ))}
        </tbody>
        <TableFooter
          totalItemCount={getTotalItemCount(currency)}
          total={getTotal(currency)}
          currency={currency}
          includeActions={includeActions}
          hideTotals={orderRequestLineItems.every(
            lineItem =>
              lineItem.line_item.product.status === ProductStatusEnum.INACTIVE
          )}
          selectable={selectable}
        />
      </Table>
    </div>
  )
}

export default OrderRequestSpendTable
