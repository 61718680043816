import { useCallback } from 'react'
import useSWR, { type KeyedMutator } from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  OrderRequestSupplierOrderMethodEnum,
  type Supplier,
  type OrderRequestSupplier,
  type OrderRequestSuppliersResource,
  type OrderRequestsApiUpdateOrderRequestSuppliersRequest,
  SupplierOrderMethodEnum
} from '@amici/myamici-api-client'
import useSWRMutation from 'swr/mutation'
import { UserModule } from '../../common/types/user-module'

export interface UseOrderRequestSuppliersHook {
  supplierDetails: OrderRequestSupplier[]
  isLoading: boolean
  mutate: KeyedMutator<OrderRequestSuppliersResource>
  getOrderMethod: (
    supplier: Supplier
  ) => OrderRequestSupplierOrderMethodEnum | undefined
  handleUpdate: (
    suppliers: OrderRequestSupplier[]
  ) => Promise<OrderRequestSuppliersResource>
}

function useOrderRequestSuppliers (
  orderRequestId = ''
): UseOrderRequestSuppliersHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: { getOrderRequestSuppliers, updateOrderRequestSuppliers },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, mutate } = useSWR<
    OrderRequestSuppliersResource,
    Error
  >(
    accountId && orderRequestId
      ? ['order-request-suppliers', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getOrderRequestSuppliers, { orderRequestId, accountId })
  )

  const { trigger: update } = useSWRMutation(
    accountId ? ['order-request-suppliers', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: OrderRequestsApiUpdateOrderRequestSuppliersRequest }
    ) => await fetcher(updateOrderRequestSuppliers, arg),
    { populateCache: true, revalidate: false }
  )

  const handleUpdate = async (
    suppliers: OrderRequestSupplier[]
  ): Promise<OrderRequestSuppliersResource> =>
    await update({
      orderRequestId,
      orderRequestSupplier: suppliers,
      accountId
    })

  const getOrderMethod = useCallback(
    (supplier: Supplier): OrderRequestSupplierOrderMethodEnum | undefined => {
      if (isLoading) {
        return undefined
      }

      const customOrderMethod = data?.content?.find(
        orderRequestSupplier => orderRequestSupplier.supplierId === supplier.id
      )?.order_method

      if (customOrderMethod) {
        return customOrderMethod
      }

      if (supplier.order_method === SupplierOrderMethodEnum.MANUAL) {
        return OrderRequestSupplierOrderMethodEnum.MANUAL
      }

      if (
        supplier.consolidate_orders &&
        activeAccount?.modules?.includes(UserModule.HasConsolidationModule)
      ) {
        return OrderRequestSupplierOrderMethodEnum.CONSOLIDATED
      }

      return OrderRequestSupplierOrderMethodEnum.DIRECT
    },
    [activeAccount?.modules, data?.content, isLoading]
  )

  return {
    supplierDetails: data?.content ?? [],
    isLoading,
    mutate,
    getOrderMethod,
    handleUpdate
  }
}

export default useOrderRequestSuppliers
