import useSWR, { type KeyedMutator } from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  type OrderRequestBase,
  type OrderRequestsApiUpdateOrderRequestRequest,
  type OrderRequestResource,
  type OrderRequestsApiCancelOrderRequestRequest,
  type OrderRequestsApiWithdrawOrderRequestRequest
} from '@amici/myamici-api-client'
import useSWRMutation from 'swr/mutation'

interface UseOrderRequestHook {
  data?: OrderRequestResource
  mutate: KeyedMutator<OrderRequestResource>
  isLoading: boolean
  isUpdating: boolean
  isCancelling: boolean
  isWithdrawing: boolean
  update: (
    orderRequest: OrderRequestBase
  ) => Promise<OrderRequestResource | undefined>
  cancel: (reason: string) => Promise<void>
  withdraw: (reason: string) => Promise<void>
}

function useOrderRequest (orderRequestId = ''): UseOrderRequestHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: {
      getOrderRequest,
      updateOrderRequest,
      cancelOrderRequest,
      withdrawOrderRequest
    },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, mutate } = useSWR<OrderRequestResource, Error>(
    accountId && orderRequestId
      ? ['order-request', orderRequestId, accountId]
      : null,
    async () => await fetcher(getOrderRequest, { orderRequestId, accountId })
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation<
    OrderRequestResource,
    Error,
    ['order-request', string, string] | null,
    OrderRequestsApiUpdateOrderRequestRequest
  >(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }) => await fetcher(updateOrderRequest, arg),
    { populateCache: true, revalidate: false }
  )

  const { trigger: triggerCancel, isMutating: isCancelling } = useSWRMutation(
    accountId ? ['order-request', orderRequestId, accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiCancelOrderRequestRequest }) => {
      await fetcher(cancelOrderRequest, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerWithdraw, isMutating: isWithdrawing } =
    useSWRMutation(
      accountId ? ['order-request', orderRequestId, accountId] : null,
      async (
        _,
        { arg }: { arg: OrderRequestsApiWithdrawOrderRequestRequest }
      ) => {
        await fetcher(withdrawOrderRequest, arg)
      },
      { populateCache: false, revalidate: true }
    )

  const update = async (
    orderRequest: OrderRequestBase
  ): Promise<OrderRequestResource | undefined> => {
    if (!accountId || !orderRequestId) {
      return
    }

    return await triggerUpdate({
      orderRequestBase: orderRequest,
      orderRequestId,
      accountId
    })
  }

  const cancel = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerCancel({
        cancellationReason: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  const withdraw = async (reason: string): Promise<void> => {
    if (accountId && orderRequestId) {
      await triggerWithdraw({
        withdrawOrderRequestRequest: { reason },
        orderRequestId,
        accountId
      })
    }
  }

  return {
    data,
    mutate,
    update,
    cancel,
    withdraw,
    isLoading,
    isUpdating,
    isCancelling,
    isWithdrawing
  }
}

export default useOrderRequest
