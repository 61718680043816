import useApi from '../../common/hooks/useApi'
import {
  type Execution,
  type ExecutionsApiCancelWorkflowExecutionRequest,
  type ExecutionsApiCompleteWorkflowExecutionStateRequest,
  type ExecutionsApiCreateWorkflowExecutionRequest,
  ExecutionStatus,
  RelationType
} from '@amici/myamici-workflow-client'
import useAccounts from '../../common/hooks/useAccounts'
import useSWRMutation from 'swr/mutation'
import useSWR, { type KeyedMutator } from 'swr'

export interface UseOrderRequestWorkflowHook {
  activeExecution?: Execution
  lastExecution?: Execution
  isLoading: boolean
  mutate: KeyedMutator<Execution[]>
  create: (workflowId: string) => Promise<Execution | null>
  isCreating: boolean
  cancel: (
    executionId: string,
    reason: string,
    terminating: boolean
  ) => Promise<Execution | null>
  isCancelling: boolean
  completeExecutionState: (
    executionId: string,
    stateId: string,
    reason: string
  ) => Promise<void>
  isCompletingExecutionState: boolean
}

function useOrderRequestWorkflow (
  orderRequestId: string
): UseOrderRequestWorkflowHook {
  const {
    executionsApi: {
      getWorkflowExecutionsByRelation,
      createWorkflowExecution,
      cancelWorkflowExecution,
      completeWorkflowExecutionState
    },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, mutate } = useSWR<Execution[], Error>(
    accountId ? ['executions', orderRequestId, accountId] : null,
    async () =>
      await fetcher(getWorkflowExecutionsByRelation, {
        relation: RelationType.ORDER_REQUEST,
        relationId: orderRequestId,
        accountId
      })
  )

  const create = async (workflowId: string): Promise<Execution | null> => {
    if (accountId) {
      await triggerCreate({
        accountId,
        executionRequest: {
          workflow_id: workflowId,
          relations: {
            ORDER_REQUEST: orderRequestId
          }
        }
      })
    }
    return null
  }

  const cancel = async (
    executionId: string,
    reason: string,
    terminating: boolean
  ): Promise<Execution | null> => {
    if (accountId) {
      await triggerCancel({
        accountId,
        executionId,
        cancelExecutionRequest: {
          reason,
          terminating
        }
      })
    }
    return null
  }

  const completeExecutionState = async (
    executionId: string,
    stateExecutionId: string,
    reason: string
  ): Promise<void> => {
    if (accountId) {
      await triggerCompleteExecutionState({
        accountId,
        executionId,
        stateExecutionId,
        completeStateExecutionRequest: {
          reason
        }
      })
    }
  }

  const { trigger: triggerCreate, isMutating: isCreating } = useSWRMutation(
    accountId ? ['executions', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: ExecutionsApiCreateWorkflowExecutionRequest }
    ) => {
      await fetcher(createWorkflowExecution, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerCancel, isMutating: isCancelling } = useSWRMutation(
    accountId ? ['executions', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: ExecutionsApiCancelWorkflowExecutionRequest }
    ) => {
      await fetcher(cancelWorkflowExecution, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const {
    trigger: triggerCompleteExecutionState,
    isMutating: isCompletingExecutionState
  } = useSWRMutation(
    accountId ? ['executions', orderRequestId, accountId] : null,
    async (
      _,
      { arg }: { arg: ExecutionsApiCompleteWorkflowExecutionStateRequest }
    ) => {
      await fetcher(completeWorkflowExecutionState, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const activeExecution = data?.find(
    execution =>
      execution.status === ExecutionStatus.IN_PROGRESS ||
      execution.status === ExecutionStatus.FAILED
  )

  const sortedData = data?.toSorted(
    (a, b) => Date.parse(b.created) - Date.parse(a.created)
  )
  const lastExecution = sortedData?.find(
    execution =>
      execution.status === ExecutionStatus.COMPLETED ||
      execution.status === ExecutionStatus.IN_PROGRESS ||
      execution.status === ExecutionStatus.FAILED
  )

  return {
    activeExecution,
    lastExecution,
    isLoading,
    mutate,
    create,
    isCreating,
    cancel,
    isCancelling,
    completeExecutionState,
    isCompletingExecutionState
  }
}

export default useOrderRequestWorkflow
