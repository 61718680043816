import {
  type OrderResource,
  type DeliveryReceipt,
  type LineItem
} from '@amici/myamici-api-client'
import {
  useState,
  type ReactElement,
  useEffect,
  type FormEvent,
  useMemo,
  useRef
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  type UseFormRegister,
  useFieldArray,
  useForm,
  type FieldErrors,
  type UseFormTrigger
} from 'react-hook-form'
import { Button, Form, FormGroup } from 'react-bootstrap'
import { format, formatISO } from 'date-fns'
import classNames from 'classnames'
import useAccounts from '../../common/hooks/useAccounts'
import useReceiveLineItems from '../hooks/useReceiveLineItems'
import useUnreceiveLineItem from '../hooks/useUnreceiveLineItem'
import MaConfirm from '../../common/components/MaConfirm'
import MaDatePicker from '../../common/components/MaDatePicker'
import ReceiveLineItemFieldset from './ReceiveLineItemFieldset'
import styles from '../assets/scss/ReceiveLineItemsModal.module.scss'

export interface ReceiveLineItemsModalProps {
  variant: 'item' | 'order'
  order?: OrderResource | null
  lineItems: LineItem[]
  onClose: () => void
  onSuccess: (items: DeliveryReceipt[]) => void
  onError: (items: DeliveryReceipt[]) => void
}

export interface ReceiveLineItemsFormValues {
  lineItems: DeliveryReceipt[]
  comment: string
  reason: string
}

function CommentControl ({
  variant = 'comment',
  required,
  register,
  errors,
  triggerValidation
}: Readonly<{
  variant: 'comment' | 'reason'
  required?: boolean
  register: UseFormRegister<ReceiveLineItemsFormValues>
  errors: FieldErrors<ReceiveLineItemsFormValues>
  triggerValidation: UseFormTrigger<ReceiveLineItemsFormValues>
}>): ReactElement {
  const { t } = useTranslation()
  const maxLength = 255

  useEffect(() => {
    void triggerValidation()
  }, [variant, required, triggerValidation])

  return (
    <FormGroup
      className={classNames(styles.control, styles.comment)}
      controlId={variant}
    >
      {variant === 'comment' && (
        <Form.Label className={classNames({ required })}>
          {t('receive_order.form.label.add_comment')}
        </Form.Label>
      )}
      {variant === 'reason' && (
        <Form.Label className="text-warning">
          {t('receive_order.form.label.reason')}
        </Form.Label>
      )}
      <Form.Control
        as="textarea"
        rows={2}
        {...register(variant, {
          maxLength: {
            value: maxLength,
            message: t('validation.error.receive_order_comment.max_length', {
              count: maxLength
            })
          },
          required: {
            value: required || variant === 'reason',
            message: t(
              'validation.error.receive_order_unreceive_reason.required'
            )
          }
        })}
        isInvalid={!!errors?.[variant]}
      />
      {!!errors?.[variant] && (
        <Form.Control.Feedback type="invalid">
          {t(errors?.[variant]?.message ?? '')}
        </Form.Control.Feedback>
      )}
    </FormGroup>
  )
}

function ReceiveLineItemsModal ({
  variant,
  order,
  lineItems,
  onClose,
  onSuccess,
  onError
}: Readonly<ReceiveLineItemsModalProps>): ReactElement {
  const today = useMemo(() => new Date(), [])
  const { t } = useTranslation()
  const { accountProfile } = useAccounts()
  const { submit: receive, isMutating: isReceiving } = useReceiveLineItems()
  const { submit: unreceive, isMutating: isUnreceiving } =
    useUnreceiveLineItem()
  const [mode, setMode] = useState<'receive' | 'unreceive'>('receive')
  const [sharedDate, setSharedDate] = useState('')
  const show = !!order && lineItems.length > 0
  const isBusy = isReceiving || isUnreceiving
  const modalRef = useRef<any>(null)

  const datepickerContainer = modalRef.current
    ? modalRef.current.dialog
    : undefined

  const defaultValues = {
    lineItems: [{ line_item_id: '0', quantity: 0, date: '', closed: false }],
    comment: '',
    reason: ''
  }

  const hasIntegratedGoodsReceipts = !!(
    (accountProfile?.client?.order_integration_id ?? 0) > 0 &&
    accountProfile?.client?.has_integrated_goods_receipts
  )

  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    trigger: triggerValidation,
    formState: { errors, isValid },
    reset,
    clearErrors
  } = useForm<ReceiveLineItemsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues
  })

  const { fields, update, remove } = useFieldArray({
    name: 'lineItems',
    control,
    shouldUnregister: true
  })

  useEffect(() => {
    remove()
    lineItems.forEach((lineItem, index) => {
      update(index, {
        line_item_id: lineItem.id,
        quantity:
          mode === 'receive'
            ? // XXX: Nested ternary to set closed line item's quantity to 0
              lineItem.fully_received
              ? 0
              : lineItem.quantity - (lineItem.quantity_received ?? 0)
            : (lineItem.quantity_received ?? 0),
        date: formatISO(today, { representation: 'date' }),
        closed: lineItem.fully_received
      })
    })

    setSharedDate(formatISO(today, { representation: 'date' }))
  }, [hasIntegratedGoodsReceipts, lineItems, mode, remove, today, update])

  const handleFormSubmit = async (
    e?: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e?.preventDefault()

    const orderId = order?.id
    const items = getValues().lineItems
    const comment = getValues().comment.trim()
    const reason = getValues().reason

    const itemsToSubmit = items.filter(
      item => comment || (item.quantity > 0 && !!item.date)
    )

    const emptyLineItems =
      itemsToSubmit.length < 1 ||
      itemsToSubmit.every(
        item => item.quantity === 0 && !item.closed && !comment
      )

    const isValid = await triggerValidation()

    if (emptyLineItems) {
      onClose()
      return
    }

    if (!isValid) {
      return
    }

    onClose()

    if (mode === 'receive' && orderId) {
      if (comment.length > 0) {
        itemsToSubmit[0].comment = comment
      }
      if (
        isSingleLineItem &&
        lineItems[0].quantity - (lineItems[0]?.quantity_received ?? 0) ===
          itemsToSubmit[0].quantity
      ) {
        itemsToSubmit[0].closed = null
      }
      await handleReceive(orderId, itemsToSubmit)
    }

    if (mode === 'unreceive' && orderId) {
      await handleUnreceive(orderId, itemsToSubmit, reason)
    }

    reset(defaultValues)
    setMode('receive')
  }

  const handleReceive = async (
    orderId: string,
    items: DeliveryReceipt[]
  ): Promise<void> => {
    try {
      await receive(orderId, items)
      onSuccess(items)
    } catch {
      onError(items)
    }
  }

  const handleUnreceive = async (
    orderId: string,
    items: DeliveryReceipt[],
    reason: string
  ): Promise<void> => {
    const lineItemsToUnreceive = lineItems.map(lineItem => ({
      ...lineItem,
      spend_categories: [],
      quantity_received:
        (lineItem?.quantity_received ?? 0) -
        (items.find(item => item.line_item_id === lineItem.id)?.quantity ?? 0),
      reason
    }))

    try {
      await unreceive(orderId, lineItemsToUnreceive)
      onSuccess(items)
    } catch {
      onError(items)
    }
  }

  const handleClearAll = (): void => {
    fields.forEach((_field, index) => {
      update(index, { ...getValues('lineItems')[index], quantity: 0, date: '' })
    })
    setSharedDate('')
    clearErrors()
  }

  const handleSetAllDates = (date: Date): void => {
    const formattedDate = formatISO(date, { representation: 'date' })

    fields.forEach((_field, index) => {
      update(index, { ...getValues('lineItems')[index], date: formattedDate })
    })

    setSharedDate(formatISO(date, { representation: 'date' }))
  }

  const isSingleLineItem = lineItems.length === 1
  const showOrderFormControls = variant === 'order'
  const showCommentControl =
    (mode === 'receive' && isSingleLineItem && variant === 'item') ||
    mode === 'unreceive'
  const commentRequired =
    isSingleLineItem &&
    !!lineItems[0]?.fully_received !== !!watch('lineItems.0.closed')
  const quantityDisabled =
    (mode === 'unreceive' && hasIntegratedGoodsReceipts) ||
    // Disabling quantity input for closed line items when close checkbox is ticked
    (mode === 'receive' &&
      isSingleLineItem &&
      !!lineItems[0].fully_received &&
      !!watch('lineItems.0.closed'))
  const emptyState =
    isSingleLineItem &&
    lineItems[0].fully_received &&
    watch('lineItems.0.quantity') === 0 &&
    !!watch('lineItems.0.closed') === !!lineItems[0].fully_received &&
    !watch('comment')?.trim()

  const Title =
    variant === 'order' ? (
      <h4 className={styles.title}>
        <Trans
          i18nKey="receive_order.order.title"
          values={{ order: order?.reference }}
        />
      </h4>
    ) : (
      <h4 className={styles.title}>
        <Trans
          i18nKey="receive_order.item.title"
          values={{ order: order?.reference }}
        />
      </h4>
    )

  return (
    <MaConfirm
      ref={modalRef}
      size="lg"
      show={show}
      title={Title}
      disabled={!isValid || isBusy || emptyState}
      className={styles['receive-line-items']}
      closeLabel={t('common.button.labels.cancel')}
      confirmLabel={t('common.button.labels.save')}
      onConfirm={() => {
        void handleFormSubmit()
      }}
      onClose={onClose}
    >
      {showOrderFormControls && (
        <div className={styles['order-header']}>
          <div className={styles.content}>
            <section className={styles['order-info']}>
              {order?.supplier?.name && (
                <h6 className={styles.supplier}>{order.supplier.name}</h6>
              )}
              <p className={styles['ordered-by']}>
                <Trans
                  i18nKey="order.card.ordered_by"
                  values={{
                    name: order?.ordered_by?.name,
                    date: format(
                      new Date(order?.order_date ?? 0),
                      'do MMMM yyyy'
                    )
                  }}
                  components={{ bold: <b /> }}
                />
              </p>
            </section>

            <section className={styles.controls}>
              <div className={styles.row}>
                <FormGroup
                  className={classNames(styles.control, styles.quantity)}
                >
                  <Form.Label>
                    {t('receive_order.form.label.quantities')}
                  </Form.Label>
                  <Button
                    variant="outline-primary"
                    className={classNames(styles['clear-all'], 'rounded')}
                    onClick={handleClearAll}
                  >
                    {t('common.button.labels.clear_all')}
                  </Button>
                </FormGroup>

                <FormGroup className={classNames(styles.control, styles.date)}>
                  <Form.Label>
                    {t('receive_order.form.label.set_all_dates')}
                  </Form.Label>
                  <MaDatePicker
                    container={datepickerContainer}
                    selected={sharedDate ? new Date(sharedDate) : undefined}
                    disabled={{ after: today }}
                    onSelect={day => {
                      handleSetAllDates(day || today)
                    }}
                  />
                </FormGroup>
              </div>
            </section>
          </div>
        </div>
      )}

      <Form
        onSubmit={e => {
          void handleFormSubmit(e)
        }}
      >
        {fields.map((field, index) => (
          <ReceiveLineItemFieldset
            container={datepickerContainer}
            variant={variant}
            className={classNames({
              [styles.single]: isSingleLineItem
            })}
            quantityDisabled={quantityDisabled}
            mode={mode}
            key={field.id}
            index={index}
            lineItem={lineItems[index]}
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
            errors={errors}
            triggerValidation={triggerValidation}
            onModeChange={mode => {
              setMode(mode)
            }}
          />
        ))}

        {showCommentControl && (
          <CommentControl
            variant={mode === 'unreceive' ? 'reason' : 'comment'}
            required={commentRequired}
            register={register}
            errors={errors}
            triggerValidation={triggerValidation}
          />
        )}
      </Form>
    </MaConfirm>
  )
}

export default ReceiveLineItemsModal
