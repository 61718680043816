import { type ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from '../assets/scss/GroupsTable.module.scss'
import { type Group } from '@amici/myamici-workflow-client'

interface GroupsTableProps {
  readonly groups: Group[] | undefined
}

function GroupsTable ({ groups }: GroupsTableProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Table bordered responsive className={styles.table}>
      <thead>
        <tr>
          <th className={styles.name}>
            {t('workflow_groups.table.header.name')}
          </th>
          <th>{t('workflow_groups.table.header.description')}</th>
          <th className={styles.actions}>
            {t('workflow_groups.table.header.actions')}
          </th>
        </tr>
      </thead>
      <tbody>
        {groups?.map(group => (
          <tr key={group.id}>
            <td className={styles.name}>{group.name}</td>
            <td>{group.description}</td>
            <td></td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default GroupsTable
