import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type OrderRequestResource } from '@amici/myamici-api-client'
import useOrderRequest from '../hooks/useOrderRequest'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import useOrderRequestWorkflow from '../hooks/useOrderRequestWorkflow'
import useWorkflowTasks from '../../workflow/hooks/useWorkflowTasks'

interface OrderRequestWithdrawModalProps {
  show: boolean
  orderRequest: OrderRequestResource
  workflowExecutionId: string
  onClose: () => void
}

function OrderRequestWithdrawModal ({
  show,
  orderRequest,
  workflowExecutionId,
  onClose
}: Readonly<OrderRequestWithdrawModalProps>): ReactElement {
  const { t } = useTranslation()
  const { showToastMessage } = useToastNotification()
  const { cancel: cancelWorkflow, isCancelling: isCancellingWorkflow } =
    useOrderRequestWorkflow(orderRequest.id)
  const { mutate: refreshOrderRequest } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { mutate: refreshTasks } = useWorkflowTasks({})

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  return (
    <OrderRequestStatusUpdateModal
      title={t('order_request.status_update.withdraw.title', {
        ref: orderRequest.order_ref
      })}
      description={t('order_request.status_update.withdraw.description')}
      show={show}
      onClose={onClose}
      onConfirm={(reason: string): void => {
        trackEvent('cancel')
        void cancelWorkflow(workflowExecutionId, reason, false)
          .then(() => {
            void refreshHistory()
            void refreshOrderRequest()
            void refreshTasks()
          })
          .catch(() => {
            showToastMessage(
              'danger',
              t('order_request.status_update.withdraw.failure', {
                ref: orderRequest.order_ref
              })
            )
          })
        onClose()
      }}
      disabled={isCancellingWorkflow}
      confirmLabel={t('order_request.status_update.withdraw.confirm')}
      reasonLabel={t(
        'order_request.status_update.withdraw.withdrawal_reason.label'
      )}
      reasonRequired={true}
    />
  )
}
export default OrderRequestWithdrawModal
