import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { MaSelect, MaSelectItem } from './MaSelect'
import DynamicPagination from './DynamicPagination'
import '../assets/scss/MaPagination.scss'

interface MaPagination extends HTMLAttributes<HTMLElement> {
  totalPages: number
  currentPage: number
  sizeOptions: number[]
  pageSize: number
  pageStartRow: number
  pageEndRow: number
  totalRowsCount: number
  standalone: boolean
  onPageChange: (value: number) => void
  onPageSizeChange: (value: number) => void
}

function MaPagination ({
  totalPages,
  currentPage,
  pageSize,
  sizeOptions,
  pageStartRow,
  pageEndRow,
  totalRowsCount,
  standalone,
  onPageChange,
  onPageSizeChange,
  ...props
}: Readonly<MaPagination>): ReactElement {
  const { t } = useTranslation()

  return (
    <Row
      className={classNames('ma-pagination', { standalone }, props.className)}
      role="navigation"
    >
      <Col xs="6" sm="4" lg={{ offset: 3, span: 2 }}>
        <Form.Group className="page-size">
          <Form.Label id="page-size">
            {t('common.table.items_per_page')}
          </Form.Label>

          <MaSelect
            value={pageSize.toString()}
            onValueChange={value => {
              onPageSizeChange(parseInt(value))
            }}
            data-testid="page-size-select"
            aria-labelledby="page-size"
          >
            {sizeOptions.map(sizeOption => (
              <MaSelectItem key={sizeOption} value={sizeOption.toString()}>
                {sizeOption}
              </MaSelectItem>
            ))}
          </MaSelect>
        </Form.Group>
      </Col>
      <Col
        xs="6"
        sm="4"
        lg="2"
        className="d-flex justify-content-center align-items-center"
      >
        <DynamicPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </Col>
      <Col
        xs="12"
        sm="4"
        lg="2"
        className="d-sm-flex d-none justify-content-end align-items-center"
      >
        <p
          className={classNames('total', 'float-md-end')}
          data-testid="total-rows"
        >
          {t('common.table.rows', {
            start: pageStartRow,
            end: pageEndRow,
            total: totalRowsCount
          })}
        </p>
      </Col>
    </Row>
  )
}

export default MaPagination
