import { type ReactElement } from 'react'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import MaConfirm from '../../common/components/MaConfirm'
import FormControlTextArea from '../../common/components/FormControlTextArea'
import { type Control, useForm } from 'react-hook-form'

export interface PurchaseOrderLineItemAddNoteModalProps {
  lineItem?: OrderLineItemResource
  onSave: (updatedLineItem: OrderLineItemResource) => void
  onClose: () => void
}

export const NOTE_MAX_LENGTH = 1000

function PurchaseOrderLineItemAddNoteModal ({
  lineItem,
  onSave,
  onClose
}: Readonly<PurchaseOrderLineItemAddNoteModalProps>): ReactElement {
  const { t } = useTranslation()
  const {
    control,
    formState: { isValid },
    getValues,
    watch,
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      note: lineItem?.notes ?? ''
    }
  })

  const handleConfirm = (): void => {
    if (!lineItem) {
      return
    }

    onSave({
      ...lineItem,
      notes: getValues('note').trim()
    })
    handleClose()
  }

  const handleClose = (): void => {
    reset()
    onClose()
  }

  return (
    <MaConfirm
      size="lg"
      show={lineItem}
      onConfirm={handleConfirm}
      onClose={handleClose}
      title={t('purchase_order.order_item.actions.add_note')}
      confirmLabel={t('common.button.labels.save')}
      disabled={!isValid}
    >
      <Form>
        <FormControlTextArea
          name="note"
          control={control as unknown as Control}
          value={watch('note')}
          rows={6}
          required={false}
          maxLength={NOTE_MAX_LENGTH}
          placeholder={t('purchase_order.order_item.add_note.placeholder')}
        />
      </Form>
    </MaConfirm>
  )
}

export default PurchaseOrderLineItemAddNoteModal
