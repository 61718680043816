import { type Task as PurchaseOrderTask } from '@amici/myamici-api-client'
import { type Task as WorkflowTask } from '@amici/myamici-workflow-client'

export enum TaskType {
  PURCHASE_ORDER,
  WORKFLOW
}

export type Task = {
  created: string
  task_type: TaskType
} & (PurchaseOrderTask | WorkflowTask)
