import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum
} from '@amici/myamici-api-client'
import type UserAccount from '../../common/models/UserAccount'
import usePurchasingPermissions from '../../purchasing/hooks/usePurchasingPermissions'

const { NEW, REJECTED, WITHDRAWN, PENDING } = OrderRequestAllOfOrderStatusEnum

interface UseOrderRequestPermissionsProps {
  orderRequest: OrderRequest | undefined
  account: UserAccount | null
}

export interface UseOrderRequestPermissionsResult {
  isFinanceUser: boolean
  isRequestedByUser: boolean
  isCreatedByUser: boolean
  canCancel: boolean
  canEdit: boolean
  canWithdraw: boolean
  canApproveAsPurchasingAdmin: boolean
}

function useOrderRequestPermissions ({
  orderRequest,
  account
}: Readonly<UseOrderRequestPermissionsProps>): UseOrderRequestPermissionsResult {
  const { isFinanceUser, isPurchasingAdmin } = usePurchasingPermissions(account)
  const isRequestedByUser =
    account?.accountId === orderRequest?.requested_by?.id
  const isCreatedByUser = account?.accountId === orderRequest?.created_by?.id
  const isRequestedByOrCreatedByUser = isRequestedByUser || isCreatedByUser

  const orderRequestStatus = orderRequest?.order_status
  const canCancel =
    (isRequestedByOrCreatedByUser || isPurchasingAdmin) &&
    (orderRequestStatus === NEW ||
      orderRequestStatus === REJECTED ||
      orderRequestStatus === WITHDRAWN ||
      orderRequestStatus === PENDING)
  const canEdit =
    (isRequestedByOrCreatedByUser || isFinanceUser) &&
    (orderRequestStatus === NEW ||
      orderRequestStatus === REJECTED ||
      orderRequestStatus === WITHDRAWN)
  const canWithdraw =
    (isRequestedByOrCreatedByUser || isPurchasingAdmin) &&
    orderRequestStatus === PENDING
  const canApproveAsPurchasingAdmin =
    isPurchasingAdmin && orderRequestStatus === PENDING

  return {
    isFinanceUser,
    isRequestedByUser,
    isCreatedByUser,
    canCancel,
    canEdit,
    canWithdraw,
    canApproveAsPurchasingAdmin
  }
}

export default useOrderRequestPermissions
