import MaPageTitle from '../../common/components/MaPageTitle'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'
import GroupsTable from '../components/GroupsTable'
import useWorkflowGroups from '../hooks/useWorkflowGroups'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/WorkflowGroups.module.scss'

function WorkflowGroups (): ReactElement {
  const { t } = useTranslation()
  const { isLoading, groups } = useWorkflowGroups()

  return (
    <Container fluid="auto" className="ma-page">
      <MaPageTitle>{t('workflow_groups.title')}</MaPageTitle>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.groups}>
          <GroupsTable groups={groups} />
          {groups?.length === 0 && (
            <p className={styles.empty}>{t('workflow_groups.empty')}</p>
          )}
        </div>
      )}
    </Container>
  )
}

export default WorkflowGroups
