import {
  RelationType,
  type Task as WorkflowTask
} from '@amici/myamici-workflow-client'
import type { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import MaCard from '../../common/components/MaCard'
import { Trans, useTranslation } from 'react-i18next'
import styles from '../../tasks/assets/scss/TaskCard.module.scss'
import TimeAgo from '../../common/components/TimeAgo'

function WorkflowTaskCard ({
  task
}: Readonly<{
  task: WorkflowTask
}>): ReactElement {
  const { t } = useTranslation()
  const orderRequestRelation = task.relations[RelationType.ORDER_REQUEST]

  return (
    <MaCard className={styles['task-card']}>
      <MaCard.Header className={styles.header}>
        {t('order_request.task.approval.header')}
      </MaCard.Header>
      <MaCard.Body>
        <MaCard.Text>
          <Trans
            i18nKey={'order_request.task.approval.body'}
            values={{
              reference: orderRequestRelation.reference,
              value: orderRequestRelation.value,
              currency: orderRequestRelation.currency,
              requested_by: orderRequestRelation.requestedBy.name
            }}
            components={{
              OrderRequestLink: (
                <Link
                  to={`/purchasing/order-requests/${orderRequestRelation.id}`}
                />
              )
            }}
          />
        </MaCard.Text>
      </MaCard.Body>
      <MaCard.Footer className={styles.footer}>
        <TimeAgo i18nKey={'task.card.created'} date={task.created} />
      </MaCard.Footer>
    </MaCard>
  )
}

export default WorkflowTaskCard
