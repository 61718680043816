import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type Task, TaskType } from '../models/Task'
import { type Task as PurchaseOrderTask } from '@amici/myamici-api-client'
import { type Task as WorkflowTask } from '@amici/myamici-workflow-client'
import PurchaseOrderTaskCard from './PurchaseOrderTaskCard'
import styles from '../assets/scss/TasksGrid.module.scss'
import WorkflowTaskCard from '../../workflow/components/WorkflowTaskCard'

function TasksGrid ({
  tasks,
  totalTasks
}: Readonly<{
  tasks: Task[]
  totalTasks: number
}>): ReactElement {
  const { t } = useTranslation()

  if (!tasks || totalTasks < 1) {
    return (
      <>
        <p className="text-center">{t('tasks.list.no_tasks.part_1')}</p>
        <p className="text-center">{t('tasks.list.no_tasks.part_2')}</p>
      </>
    )
  }

  return tasks.length > 0 ? (
    <ul className={styles.tasks}>
      {tasks.map((task: Task) => (
        <li key={task.id}>
          {task.task_type === TaskType.PURCHASE_ORDER ? (
            <PurchaseOrderTaskCard task={task as PurchaseOrderTask} />
          ) : (
            <WorkflowTaskCard task={task as WorkflowTask} />
          )}
        </li>
      ))}
    </ul>
  ) : (
    <p className="text-center">{t('tasks.list.nothing_found')}</p>
  )
}

export default TasksGrid
