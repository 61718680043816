import {
  type Condition,
  type ConditionState,
  FieldType,
  NumericFieldOperator
} from '@amici/myamici-workflow-client'
import { type ReactElement, useEffect, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import styles from '../assets/scss/Workflows.module.scss'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import useCurrency from '../../common/hooks/useCurrency'
import useAccounts from '../../common/hooks/useAccounts'

const { GREATER_THAN, GREATER_THAN_OR_EQUAL, LESS_THAN, LESS_THAN_OR_EQUAL } =
  NumericFieldOperator

const MIN_VALUE = 0
const MAX_VALUE = 2147483647

function ConditionEditor ({
  condition,
  onConditionChange
}: Readonly<{
  condition: Condition
  onConditionChange: (condition: Condition) => void
}>): ReactElement {
  const id = useId()
  const { t } = useTranslation()
  const { accountProfile } = useAccounts()
  const { currencySymbol } = useCurrency()
  const [value, setValue] = useState(condition.value)

  useEffect(() => {
    setValue(value)
    if (condition.value !== value) {
      onConditionChange({
        ...condition,
        value
      })
    }
  }, [condition, onConditionChange, value])

  const onValueChange = (updatedValue: number): void => {
    if (isNaN(updatedValue) || updatedValue < MIN_VALUE) {
      setValue(MIN_VALUE)
    } else if (updatedValue > MAX_VALUE) {
      setValue(MAX_VALUE)
    } else {
      setValue(updatedValue)
    }
  }

  return (
    <Form className={styles['condition-editor']}>
      <Form.Group>
        <Form.Label id={`${id}-field`}>
          {t('workflows.condition.field')}
        </Form.Label>
        <MaSelect aria-labelledby={`${id}-field`} value={condition.field_type}>
          <MaSelectItem value={FieldType.ORDER_REQUEST_VALUE}>
            {t(
              `workflows.field_type.${FieldType.ORDER_REQUEST_VALUE.toLowerCase()}`
            )}
          </MaSelectItem>
        </MaSelect>
      </Form.Group>
      <Form.Group>
        <Form.Label id={`${id}-operator`}>
          {t('workflows.condition.operator.label')}
        </Form.Label>
        <MaSelect
          aria-labelledby={`${id}-operator`}
          value={condition.operator}
          onValueChange={value => {
            onConditionChange({
              ...condition,
              operator: value as NumericFieldOperator
            })
          }}
        >
          <MaSelectItem value={GREATER_THAN}>
            {t(
              `workflows.condition.operator.numeric.${GREATER_THAN.toLowerCase()}`
            )}
          </MaSelectItem>
          <MaSelectItem value={GREATER_THAN_OR_EQUAL}>
            {t(
              `workflows.condition.operator.numeric.${GREATER_THAN_OR_EQUAL.toLowerCase()}`
            )}
          </MaSelectItem>
          <MaSelectItem value={LESS_THAN}>
            {t(
              `workflows.condition.operator.numeric.${LESS_THAN.toLowerCase()}`
            )}
          </MaSelectItem>
          <MaSelectItem value={LESS_THAN_OR_EQUAL}>
            {t(
              `workflows.condition.operator.numeric.${LESS_THAN_OR_EQUAL.toLowerCase()}`
            )}
          </MaSelectItem>
        </MaSelect>
      </Form.Group>
      <Form.Group>
        <Form.Label id={`${id}-value`}>
          {t('workflows.condition.value', {
            currencySymbol: currencySymbol(
              accountProfile?.client?.master_currency ?? 'GBP'
            )
          })}
        </Form.Label>
        <Form.Control
          type="number"
          aria-labelledby={`${id}-value`}
          value={value}
          min={MIN_VALUE}
          max={MAX_VALUE}
          onChange={event => {
            onValueChange(parseInt(event.target.value, 10))
          }}
        />
      </Form.Group>
    </Form>
  )
}

export interface ConditionStateEditorProps {
  state: ConditionState
  onConditionChange: (condition: Condition) => void
}

function ConditionStateEditor ({
  state,
  onConditionChange
}: Readonly<ConditionStateEditorProps>): ReactElement {
  return (
    <>
      {state.conditions.map((condition, index) => (
        <ConditionEditor
          key={index}
          condition={condition}
          onConditionChange={onConditionChange}
        />
      ))}
    </>
  )
}

export default ConditionStateEditor
