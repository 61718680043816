import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import OrderRequestEditSummaryModal from './OrderRequestEditSummaryModal'
import OrderRequestCancelModal from './OrderRequestCancelModal'
import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum,
  ProductStatusEnum
} from '@amici/myamici-api-client'
import { type ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useAccounts from '../../common/hooks/useAccounts'
import OrderRequestWithdrawModal from './OrderRequestWithdrawModal'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import MaAlert from '../../common/components/MaAlert'
import useOrderRequestWorkflow from '../hooks/useOrderRequestWorkflow'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useWorkflowTasks from '../../workflow/hooks/useWorkflowTasks'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import { ExecutionStatus } from '@amici/myamici-workflow-client'

interface OrderRequestActionMenuProps {
  readonly orderRequest: OrderRequest
}

function OrderRequestActionMenu ({
  orderRequest
}: OrderRequestActionMenuProps): ReactNode {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { canCancel, canEdit, canWithdraw, canApproveAsPurchasingAdmin } =
    useOrderRequestPermissions({
      orderRequest,
      account: activeAccount
    })
  const {
    activeExecution,
    isLoading: isLoadingWorkflow,
    completeExecutionState,
    isCompletingExecutionState
  } = useOrderRequestWorkflow(orderRequest.id)
  const { mutate: refreshOrderRequest } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { mutate: refreshWorkflowTasks } = useWorkflowTasks({})
  const { lineItems } = useOrderRequestLineItems(orderRequest.id)
  const { showToastMessage } = useToastNotification()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [error, setError] = useState<string>()

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  const lineItemsValid = lineItems.every(
    item => item.line_item.product.status === ProductStatusEnum.ACTIVE
  )

  const currentStateExecutionId = activeExecution?.states.at(-1)?.id

  return canCancel || canEdit || canApproveAsPurchasingAdmin ? (
    <>
      <MaActionMenu aria-label={t('order_request.action.menu')}>
        {canEdit && (
          <MaActionMenuItem
            onClick={() => {
              setShowEditModal(true)
              trackEvent('open_edit_order_request_modal')
            }}
          >
            <Trans i18nKey="order_request.action.edit" />
          </MaActionMenuItem>
        )}
        {canApproveAsPurchasingAdmin && (
          <MaActionMenuItem
            onClick={() => {
              if (
                activeExecution &&
                currentStateExecutionId &&
                lineItemsValid
              ) {
                setShowApprovalModal(true)
              } else if (!activeExecution || !currentStateExecutionId) {
                setError('order_request.status_update.approve.error.workflow')
              } else {
                setError(
                  'order_request.status_update.approve.error.invalid_product'
                )
              }
              trackEvent('open_approve_order_request_modal')
            }}
            disabled={
              isLoadingWorkflow ||
              activeExecution?.status !== ExecutionStatus.IN_PROGRESS
            }
          >
            <Trans i18nKey="order_request.action.approve_as_purchasing_admin" />
          </MaActionMenuItem>
        )}
        {canWithdraw && (
          <MaActionMenuItem
            onClick={() => {
              if (activeExecution) {
                setShowWithdrawModal(true)
              } else {
                setError('order_request.withdraw.workflow.error')
              }
              trackEvent('open_withdraw_order_request_modal')
            }}
            disabled={isLoadingWorkflow}
          >
            <Trans i18nKey="order_request.action.withdraw" />
          </MaActionMenuItem>
        )}
        {canCancel && (
          <MaActionMenuItem
            onClick={() => {
              if (
                orderRequest.order_status !==
                  OrderRequestAllOfOrderStatusEnum.PENDING ||
                activeExecution
              ) {
                setShowCancelModal(true)
              } else {
                setError('order_request.cancel.workflow.error')
              }
              trackEvent('open_cancel_order_request_modal')
            }}
            disabled={
              orderRequest.order_status ===
                OrderRequestAllOfOrderStatusEnum.PENDING && isLoadingWorkflow
            }
          >
            <Trans i18nKey="order_request.action.cancel" />
          </MaActionMenuItem>
        )}
      </MaActionMenu>
      <OrderRequestEditSummaryModal
        orderRequest={orderRequest}
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false)
          trackEvent('close_edit_order_request_modal')
        }}
      />
      <OrderRequestCancelModal
        orderRequest={orderRequest}
        workflowExecutionId={activeExecution?.id}
        show={showCancelModal}
        onClose={() => {
          setShowCancelModal(false)
          trackEvent('close_cancel_order_request_modal')
        }}
      />
      {activeExecution && (
        <OrderRequestWithdrawModal
          orderRequest={orderRequest}
          workflowExecutionId={activeExecution.id}
          show={showWithdrawModal}
          onClose={() => {
            setShowWithdrawModal(false)
            trackEvent('close_withdraw_order_request_modal')
          }}
        />
      )}
      {activeExecution && currentStateExecutionId && (
        <OrderRequestStatusUpdateModal
          show={showApprovalModal}
          onClose={() => {
            setShowApprovalModal(false)
            trackEvent('close_approve_order_request_as_purchasing_admin_modal')
          }}
          onConfirm={approvalResponse => {
            trackEvent('approve_as_purchasing_admin')
            void completeExecutionState(
              activeExecution.id,
              currentStateExecutionId,
              approvalResponse
            )
              .then(() => {
                void refreshOrderRequest()
                void refreshHistory()
                void refreshWorkflowTasks()
              })
              .catch(() => {
                showToastMessage(
                  'danger',
                  t('order_request.status_update.approve.failure', {
                    ref: orderRequest.order_ref
                  })
                )
              })
              .finally(() => {
                setShowApprovalModal(false)
              })
          }}
          disabled={isCompletingExecutionState}
          confirmLabel={t('order_request.action.approve')}
          title={t('order_request.status_update.approve.title', {
            ref: orderRequest.order_ref
          })}
          reasonLabel={t(
            'order_request.status_update.approve.approval_response.label'
          )}
          reasonRequired={true}
        />
      )}
      <MaAlert
        variant="error"
        show={error}
        onClose={() => {
          setError(undefined)
        }}
      >
        <Trans i18nKey={error} />
      </MaAlert>
    </>
  ) : null
}

export default OrderRequestActionMenu
