import useSWR, { type KeyedMutator } from 'swr'
import { type HistoryEvent } from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import {
  RelationType,
  type CompletedTask
} from '@amici/myamici-workflow-client'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import { OrderRequestHistoryEventType } from '../types/order-request-history-event-type'

interface UseOrderRequestWorkflowHistoryHook {
  historyEntries?: HistoryEvent[]
  mutate: KeyedMutator<CompletedTask[]>
  isLoading: boolean
}

function useOrderRequestWorkflowHistory (
  orderRequestId: string
): UseOrderRequestWorkflowHistoryHook {
  const { activeAccount } = useAccounts()
  const { allAccounts } = useAllAccounts()
  const {
    workflowTasksApi: { getCompletedTasksByRelation },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, mutate } = useSWR<CompletedTask[], Error>(
    accountId && orderRequestId
      ? ['completed-workflow-tasks', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getCompletedTasksByRelation, {
        relation: RelationType.ORDER_REQUEST,
        relationId: orderRequestId,
        accountId
      })
  )

  const historyEntries = data?.map(task => ({
    date: task.completed,
    account_id: task.assignee_id,
    account_name:
      allAccounts?.content?.find(account => account.id === task.assignee_id)
        ?.name ?? '',
    event: OrderRequestHistoryEventType.COMPLETE_WORKFLOW_STATE,
    reason: task.outcome.reason,
    custom_data: {
      state_name: task.state_name,
      outcome: task.outcome.success ? 'success' : 'failure'
    }
  }))

  return {
    historyEntries,
    mutate,
    isLoading
  }
}

export default useOrderRequestWorkflowHistory
