import MaPanel, {
  MaPanelBody,
  MaPanelHeader
} from '../../common/components/MaPanel'
import styles from '../assets/scss/OrderRequest.module.scss'
import WorkflowExecutionGraph from '../../workflow/components/WorkflowExecutionGraph'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useOrderRequestWorkflow from '../hooks/useOrderRequestWorkflow'
import LoadingSpinner from '../../common/components/LoadingSpinner'

interface OrderRequestWorkflowStatusPanelProps {
  orderRequestId: string
}

function OrderRequestWorkflowStatusPanel ({
  orderRequestId
}: Readonly<OrderRequestWorkflowStatusPanelProps>): ReactElement {
  const { t } = useTranslation()
  const { lastExecution, isLoading } = useOrderRequestWorkflow(orderRequestId)
  return (
    <MaPanel className={styles['workflow-status-panel']}>
      <MaPanelHeader
        className={styles['workflow-status-panel-header']}
        text={t('order_request.workflow_status.title')}
      />
      <MaPanelBody className={styles['workflow-status-panel-body']}>
        {isLoading ? (
          <LoadingSpinner />
        ) : lastExecution ? (
          <WorkflowExecutionGraph execution={lastExecution} />
        ) : (
          <p className={'text-danger'}>
            {t('order_request.workflow_status.error')}
          </p>
        )}
      </MaPanelBody>
    </MaPanel>
  )
}

export default OrderRequestWorkflowStatusPanel
