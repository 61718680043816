import useApi from '../../common/hooks/useApi'
import useSWR, { type KeyedMutator } from 'swr'
import { type Task } from '@amici/myamici-workflow-client'
import useAccounts from '../../common/hooks/useAccounts'
import { UserPermission } from '../../common/types/user-permission'

interface UseWorkflowTasksHook {
  tasks: Task[] | undefined
  mutate: KeyedMutator<Task[]>
}

interface UseWorkflowTasksHookProps {
  refreshInterval?: number
}

function useWorkflowTasks ({
  refreshInterval
}: Readonly<UseWorkflowTasksHookProps>): UseWorkflowTasksHook {
  const {
    workflowTasksApi: { getOpenTasks },
    fetcher
  } = useApi()

  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const { data: tasks, mutate } = useSWR<Task[], Error>(
    accountId &&
      activeAccount?.permissions.includes(UserPermission.HasNextGenPurchasing)
      ? ['workflow-tasks', accountId]
      : null,
    async () => await fetcher(getOpenTasks, { accountId }),
    { refreshInterval }
  )

  return {
    tasks,
    mutate
  }
}

export default useWorkflowTasks
