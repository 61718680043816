import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

const MINUTE = 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7

interface TimeAgoProps {
  i18nKey?: string
  date: string
}

function TimeAgo ({
  i18nKey = 'time_ago',
  date
}: Readonly<TimeAgoProps>): ReactElement {
  const { t } = useTranslation()

  const seconds = Math.floor((Date.now() - new Date(date).valueOf()) / 1000)

  const [unit, count] =
    seconds < MINUTE
      ? ['second', seconds]
      : seconds < HOUR
        ? ['minute', Math.floor(seconds / MINUTE)]
        : seconds < DAY
          ? ['hour', Math.floor(seconds / HOUR)]
          : seconds < WEEK
            ? ['day', Math.floor(seconds / DAY)]
            : ['week', Math.floor(seconds / WEEK)]

  const timeAgo = t(i18nKey, { context: unit, unit, count })

  return <time dateTime={date}>{timeAgo}</time>
}

export default TimeAgo
