import {
  forwardRef,
  Fragment,
  type ReactElement,
  useEffect,
  useId,
  useRef,
  useState
} from 'react'
import {
  BaseStateTypeEnum,
  type EndState,
  type Execution,
  ExecutionStatus,
  type State,
  type Transition,
  WorkflowOutcome
} from '@amici/myamici-workflow-client'
import {
  BsCheckCircleFill,
  BsCircle,
  BsRecordCircle,
  BsXCircleFill
} from 'react-icons/bs'
import styles from '../assets/scss/WorkflowExcecutionGraph.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { type IconType } from 'react-icons'
import WorkflowStateExecutionDetailsModal from './WorkflowStateExecutionDetailsModal'
import { type StateExecutionDetails } from '../models/StateExecutionDetails'
import { Button } from 'react-bootstrap'

interface WorkflowExecutionGraphProps {
  execution: Execution
}

interface StateNodeProps {
  stateExecutionDetails: StateExecutionDetails
  onClick?: () => void
}

interface GraphNodeProps {
  label: string
  className: string
  Icon: IconType
  onClick?: () => void
}

const GraphNode = forwardRef<HTMLDivElement, Readonly<GraphNodeProps>>(
  function GraphNode ({ label, className, Icon, onClick }, ref): ReactElement {
    const id = useId()
    return (
      <div
        ref={ref}
        className={className}
        role="graphics-symbol"
        aria-labelledby={id}
      >
        <Icon size="24" />
        {onClick ? (
          <Button
            id={id}
            variant="link"
            className={styles.label}
            onClick={() => {
              onClick?.()
            }}
          >
            {label}
          </Button>
        ) : (
          <span id={id} className={styles.label}>
            {label}
          </span>
        )}
      </div>
    )
  }
)

const StateNode = ({
  stateExecutionDetails,
  onClick
}: Readonly<StateNodeProps>): ReactElement => {
  const state = stateExecutionDetails.state
  const visited = !!stateExecutionDetails.stateExecution
  return (
    <GraphNode
      label={stateExecutionDetails.state.name}
      Icon={visited ? BsCheckCircleFill : BsCircle}
      className={classNames([styles.node, visited && styles.visited])}
      onClick={state.type !== BaseStateTypeEnum.END ? onClick : undefined}
    />
  )
}

const CurrentNode = forwardRef<HTMLDivElement, Readonly<StateNodeProps>>(
  function CurrentNode ({ stateExecutionDetails, onClick }, ref): ReactElement {
    const state = stateExecutionDetails.state
    return (
      <GraphNode
        ref={ref}
        label={state.name}
        Icon={
          state.type === BaseStateTypeEnum.END
            ? BsCheckCircleFill
            : BsRecordCircle
        }
        className={classNames([styles.node, styles.visited])}
        onClick={onClick}
      />
    )
  }
)

const FailureNode = forwardRef<HTMLDivElement, Readonly<StateNodeProps>>(
  function FailureNode ({ stateExecutionDetails, onClick }, ref): ReactElement {
    return (
      <GraphNode
        ref={ref}
        label={stateExecutionDetails.state.name}
        Icon={BsXCircleFill}
        className={classNames(styles.node, styles.failure)}
        onClick={onClick}
      />
    )
  }
)

const TransitionLine = ({
  visited
}: Readonly<{ visited?: boolean }>): ReactElement => {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(styles.line, visited && styles.visited)}
      aria-label={t('order_request.workflow_status.transition')}
      role="graphics-symbol"
    />
  )
}

const futureState = (
  state: State,
  states: State[],
  transitions: Transition[]
): State | undefined => {
  const nextTransition = transitions.find(
    transition => state.name === transition.source
  )
  return states.find(value => value.name === nextTransition?.target)
}

const futureStates = (
  state: State,
  states: State[],
  transitions: Transition[]
): State[] => {
  const futureStates = []
  let nextState = futureState(state, states, transitions)
  while (nextState) {
    futureStates.push(nextState)
    nextState = futureState(nextState, states, transitions)
  }
  return futureStates
}

const WorkflowExecutionGraph = ({
  execution
}: Readonly<WorkflowExecutionGraphProps>): ReactElement => {
  const nodeRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    nodeRef.current?.scrollIntoView({
      block: 'nearest',
      inline: 'center'
    })
  }, [nodeRef, execution])

  const [selectedStateExecutionDetails, setSelectedStateExecutionDetails] =
    useState<StateExecutionDetails>()

  const visitedStates: StateExecutionDetails[] = execution.states
    .map(stateExecution => ({
      stateExecution,
      state: execution.workflow.states.find(
        state => state.name === stateExecution.state
      ) as State
    }))
    .filter(
      stateExecutionDetails =>
        stateExecutionDetails.state.type !== BaseStateTypeEnum.CONDITION
    )
  let currentState = visitedStates.pop() as StateExecutionDetails
  let failureState: StateExecutionDetails | undefined
  if (currentState.state.type === BaseStateTypeEnum.END) {
    const endState = currentState.state as EndState
    if (endState.outcome === WorkflowOutcome.FAILURE) {
      failureState = visitedStates.pop() as StateExecutionDetails
      currentState = failureState
    } else if (execution.status === ExecutionStatus.FAILED) {
      failureState = currentState
    }
  }
  const possibleStates: StateExecutionDetails[] = futureStates(
    currentState.state,
    execution.workflow.states,
    execution.workflow.transitions
  )
    .filter(state => state.type !== BaseStateTypeEnum.CONDITION)
    .map(state => ({ state }))

  return (
    <div className={styles.root}>
      <div className={styles.graph} role="graphics-document">
        {visitedStates.map(stateExecutionDetails => (
          <Fragment key={stateExecutionDetails.state.name}>
            <StateNode
              stateExecutionDetails={stateExecutionDetails}
              onClick={() => {
                setSelectedStateExecutionDetails(stateExecutionDetails)
              }}
            />
            <TransitionLine visited={true} />
          </Fragment>
        ))}
        {failureState ? (
          <FailureNode
            ref={nodeRef}
            stateExecutionDetails={failureState}
            onClick={() => {
              setSelectedStateExecutionDetails(failureState)
            }}
          />
        ) : (
          <CurrentNode
            ref={nodeRef}
            stateExecutionDetails={currentState}
            onClick={() => {
              setSelectedStateExecutionDetails(currentState)
            }}
          />
        )}
        {possibleStates.map(possibleState => (
          <Fragment key={possibleState.state.name}>
            <TransitionLine />
            <StateNode
              stateExecutionDetails={possibleState}
              onClick={
                !failureState
                  ? () => {
                      setSelectedStateExecutionDetails(possibleState)
                    }
                  : undefined
              }
            />
          </Fragment>
        ))}
      </div>
      {selectedStateExecutionDetails && (
        <WorkflowStateExecutionDetailsModal
          stateExecutionDetails={selectedStateExecutionDetails}
          onClose={() => {
            setSelectedStateExecutionDetails(undefined)
          }}
        />
      )}
    </div>
  )
}

export default WorkflowExecutionGraph
