import { type ReactElement, useState, useRef } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsPencilFill } from 'react-icons/bs'
import { formatISO } from 'date-fns'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from '../schemas/coshh-and-licence'
import { UserPermission } from '../../common/types/user-permission'
import useAccounts from '../../common/hooks/useAccounts'
import useProductHazards from '../hooks/useProductHazards'
import MaConfirm from '../../common/components/MaConfirm'
import MaDatePicker from '../../common/components/MaDatePicker'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import CoshhAndLicenceSectionContent from './CoshhAndLicenceSectionContent'
import FormControlTextArea from '../../common/components/FormControlTextArea'
import styles from '../assets/scss/ProductDetailsTabHazards.module.scss'

function CoshhAndLicenceSection ({
  productId
}: Readonly<{
  productId: string
}>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { data, submit, isLoading, isMutating } = useProductHazards(productId)
  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    watch,
    trigger: triggerValidation,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      coshh: {},
      licence: { has_licence: false },
      note: ''
    },
    values: {
      coshh: data?.coshh,
      licence: data?.licence,
      note: data?.note
    },
    resolver: yupResolver(schema)
  })

  const [showEditForm, setShowEditForm] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const [modalFullyVisible, setModalFullyVisible] = useState(false)
  const modalRef = useRef<any>(null)

  const datepickerContainer = modalRef.current
    ? modalRef.current.dialog
    : undefined

  const canEdit = activeAccount?.permissions.includes(
    UserPermission.IsHazardApprover
  )
  const canSubmit = canEdit && !isLoading && !isMutating

  const handleSubmit = async (): Promise<void> => {
    const isValid = await triggerValidation()

    if (!isValid) {
      return
    }

    setSubmissionError(false)

    try {
      await submit({
        coshh: getValues('coshh') ?? {},
        licence: getValues('licence') ?? { has_licence: false },
        note: getValues('note'),
        cas_hazard: {
          cas_number: ''
        },
        hazards: [],
        restrictions: []
      })

      setShowEditForm(false)
      reset()
    } catch {
      setSubmissionError(true)
    }
  }

  const handleCancel = (): void => {
    setModalFullyVisible(false)
    setShowEditForm(false)
    setSubmissionError(false)
    reset()
  }

  return (
    <section className={styles['coshh-and-licence']}>
      <div className={styles.actions}>
        {canEdit && (
          <Button
            className={styles['edit-btn']}
            aria-label="edit"
            variant="light"
            onClick={() => {
              setShowEditForm(true)
            }}
          >
            <BsPencilFill size={16} />
          </Button>
        )}
      </div>

      <CoshhAndLicenceSectionContent data={data} isLoading={isLoading} />

      <MaConfirm
        ref={modalRef}
        size="lg"
        disabled={!canSubmit}
        show={showEditForm}
        title={t('product.details.tabs.hazards.form.title')}
        closeLabel={t('common.button.labels.cancel')}
        confirmLabel={t('common.button.labels.save')}
        onShow={() => {
          setModalFullyVisible(true)
        }}
        onClose={handleCancel}
        onConfirm={() => {
          void handleSubmit()
        }}
      >
        <Form className={styles['edit-form']} aria-label="edit-form">
          <fieldset disabled={!canSubmit}>
            <Row>
              <Form.Group as={Col} lg={3} controlId="coshh-required">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.coshh_required')}
                </Form.Label>
                <Controller
                  name="coshh.has_coshh"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaSelect
                      aria-label={t(
                        'product.details.tabs.hazards.form.label.coshh_required'
                      )}
                      onValueChange={value => {
                        onChange(JSON.parse(value))
                      }}
                      value={JSON.stringify(value)}
                    >
                      {typeof data?.coshh?.has_coshh !== 'boolean' && (
                        <MaSelectItem value="null">
                          {t('product.details.tabs.hazards.form.value.n_a')}
                        </MaSelectItem>
                      )}
                      <MaSelectItem value="true">
                        {t('product.details.tabs.hazards.form.value.yes')}
                      </MaSelectItem>
                      <MaSelectItem value="false">
                        {t('product.details.tabs.hazards.form.value.no')}
                      </MaSelectItem>
                    </MaSelect>
                  )}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="coshh-reference">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.coshh_reference')}
                </Form.Label>
                <Form.Control
                  {...register('coshh.reference')}
                  isInvalid={!!errors?.coshh?.reference}
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  {t(errors?.coshh?.reference?.message ?? '')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} lg={3} controlId="coshh-review-date">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.review_date')}
                </Form.Label>
                {modalFullyVisible && (
                  <MaDatePicker
                    container={datepickerContainer}
                    selected={
                      watch('coshh.expiry_date')
                        ? new Date(getValues('coshh.expiry_date') as string)
                        : undefined
                    }
                    onSelect={day => {
                      setValue(
                        'coshh.expiry_date',
                        day ? formatISO(day, { representation: 'date' }) : null
                      )
                    }}
                  />
                )}
                <Form.Control.Feedback type="invalid" />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} lg={3} controlId="licence-held">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.licence_held')}
                </Form.Label>
                <Controller
                  name="licence.has_licence"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaSelect
                      aria-label={t(
                        'product.details.tabs.hazards.form.label.licence_held'
                      )}
                      onValueChange={value => {
                        onChange(JSON.parse(value))
                      }}
                      value={JSON.stringify(value)}
                    >
                      <MaSelectItem value="true">
                        {t('product.details.tabs.hazards.form.value.yes')}
                      </MaSelectItem>
                      <MaSelectItem value="false">
                        {t('product.details.tabs.hazards.form.value.no')}
                      </MaSelectItem>
                    </MaSelect>
                  )}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="licence-reference">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.licence_details')}
                </Form.Label>
                <Form.Control
                  {...register('licence.reference')}
                  isInvalid={!!errors?.licence?.reference}
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  {t(errors?.licence?.reference?.message ?? '')}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} lg={3} controlId="licence-expiry-date">
                <Form.Label>
                  {t('product.details.tabs.hazards.form.label.expiry_date')}
                </Form.Label>
                {modalFullyVisible && (
                  <MaDatePicker
                    container={datepickerContainer}
                    selected={
                      watch('licence.expiry_date')
                        ? new Date(getValues('licence.expiry_date') as string)
                        : undefined
                    }
                    onSelect={day => {
                      setValue(
                        'licence.expiry_date',
                        day ? formatISO(day, { representation: 'date' }) : null
                      )
                    }}
                  />
                )}
                <Form.Control.Feedback type="invalid" />
              </Form.Group>
            </Row>

            <Row>
              <FormControlTextArea
                label={t('product.details.tabs.hazards.form.label.note')}
                value={watch('note') ?? ''}
                name="note"
                required={false}
                maxLength={400}
                rows={5}
                control={control}
              />
            </Row>
          </fieldset>
        </Form>

        {submissionError && (
          <p className="text-center small text-danger m-0">
            {t('product.details.tabs.hazards.form.submission_error')}
          </p>
        )}
      </MaConfirm>
    </section>
  )
}

export default CoshhAndLicenceSection
