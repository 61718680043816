import {
  type ActionState,
  BaseStateTypeEnum,
  type BaseWorkflow,
  type Condition,
  type ConditionState,
  type State,
  type Workflow
} from '@amici/myamici-workflow-client'
import { type ReactElement, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import styles from '../assets/scss/Workflows.module.scss'
import useWorkflows from '../hooks/useWorkflows'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import classNames from 'classnames'
import { BsPersonCheckFill, BsQuestionCircleFill } from 'react-icons/bs'
import MaPanel from '../../common/components/MaPanel'
import ConditionStateEditor from './ConditionStateEditor'
import ActionStateEditor from './ActionStateEditor'

const { ACTION, CONDITION } = BaseStateTypeEnum

function StateEditor ({
  state,
  onAssignmentChange,
  onConditionChange
}: Readonly<{
  state: State
  onAssignmentChange: (assignment: string) => void
  onConditionChange: (condition: Condition) => void
}>): ReactElement | null {
  let stateEditor
  let Icon
  switch (state.type) {
    case CONDITION:
      stateEditor = (
        <ConditionStateEditor
          state={state as ConditionState}
          onConditionChange={onConditionChange}
        />
      )
      Icon = BsQuestionCircleFill
      break
    case ACTION:
      stateEditor = (
        <ActionStateEditor
          state={state as ActionState}
          onAssignmentChange={onAssignmentChange}
        />
      )
      Icon = BsPersonCheckFill
      break
    default:
      stateEditor = null
  }
  return stateEditor ? (
    <MaPanel className={styles.panel}>
      <MaPanel.Header className={styles['panel-header']}>
        <h3>{state.name}</h3>
        {Icon && <Icon size={20} />}
      </MaPanel.Header>
      <MaPanel.Body>{stateEditor}</MaPanel.Body>
    </MaPanel>
  ) : null
}

type Action =
  | { type: 'reset'; workflow: Workflow | BaseWorkflow }
  | {
      type: 'update_assignment'
      state: State
      assignment: string
    }
  | { type: 'update_condition'; state: State; condition: Condition }

interface WorkflowEditorState {
  workflow: Workflow | BaseWorkflow
}

function WorkflowEditorReducer (
  prevState: WorkflowEditorState,
  action: Action
): WorkflowEditorState {
  let workflowEditorState = prevState
  switch (action.type) {
    case 'reset':
      workflowEditorState = {
        ...prevState,
        workflow: action.workflow
      }
      break
    case 'update_assignment':
      workflowEditorState = {
        ...prevState,
        workflow: {
          ...prevState.workflow,
          states: prevState.workflow.states.map(value =>
            value.name === action.state.name
              ? {
                  ...action.state,
                  assignment_group: action.assignment
                }
              : value
          )
        }
      }
      break
    case 'update_condition':
      workflowEditorState = {
        ...prevState,
        workflow: {
          ...prevState.workflow,
          states: prevState.workflow.states.map(value =>
            value.name === action.state.name
              ? {
                  ...action.state,
                  conditions: [action.condition]
                }
              : value
          )
        }
      }
      break
  }
  return workflowEditorState
}

interface WorkflowEditorProps {
  workflow: BaseWorkflow | Workflow
}

function WorkflowEditor ({
  workflow
}: Readonly<WorkflowEditorProps>): ReactElement {
  const { t } = useTranslation()
  const { update, create, isSaving } = useWorkflows('ORDER_REQUEST')
  const [workflowEditorState, dispatch] = useReducer(WorkflowEditorReducer, {
    workflow
  })
  const { showToastMessage } = useToastNotification()

  useEffect(() => {
    dispatch({ type: 'reset', workflow })
  }, [workflow])

  const handleSave = async (): Promise<void> => {
    try {
      if ('id' in workflowEditorState.workflow) {
        await update(workflowEditorState.workflow)
      } else {
        await create(workflowEditorState.workflow)
      }
      showToastMessage('dark', t('workflows.save.success'))
    } catch {
      showToastMessage('danger', t('workflows.save.error'))
    }
  }

  return (
    <div>
      <div className={styles.header}>
        <h2>{workflowEditorState.workflow.name}</h2>
        <Button
          variant="primary"
          className={classNames('rounded', styles.button)}
          disabled={isSaving}
          onClick={() => {
            void handleSave()
          }}
        >
          {t('common.button.labels.save')}
        </Button>
      </div>
      <fieldset disabled={isSaving}>
        {workflowEditorState.workflow.states.map(state => (
          <StateEditor
            key={state.name}
            state={state}
            onAssignmentChange={assignment => {
              dispatch({ type: 'update_assignment', state, assignment })
            }}
            onConditionChange={condition => {
              dispatch({ type: 'update_condition', state, condition })
            }}
          />
        ))}
      </fieldset>
    </div>
  )
}

export default WorkflowEditor
