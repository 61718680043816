import useApi from '../../common/hooks/useApi'
import {
  RelationType,
  type Task,
  type TasksApiUpdateTaskRequest
} from '@amici/myamici-workflow-client'
import useAccounts from '../../common/hooks/useAccounts'
import useSWRMutation from 'swr/mutation'
import useWorkflowTasks from '../../workflow/hooks/useWorkflowTasks'

interface UseOrderRequestWorkflowTaskHook {
  approve: (response: string) => Promise<void>
  reject: (reason: string) => Promise<void>
  task: Task | undefined
  isApproving: boolean
  isRejecting: boolean
}

function useOrderRequestWorkflowTask (
  orderRequestId: string
): UseOrderRequestWorkflowTaskHook {
  const {
    workflowTasksApi: { updateTask },
    fetcher
  } = useApi()
  const { tasks } = useWorkflowTasks({ refreshInterval: 2000 })
  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const task = tasks?.find(
    task => task.relations?.[RelationType.ORDER_REQUEST].id === orderRequestId
  )

  const { trigger: triggerApprove, isMutating: isApproving } = useSWRMutation(
    accountId ? ['workflow-tasks', accountId] : null,
    async (_, { arg }: { arg: TasksApiUpdateTaskRequest }) => {
      await fetcher(updateTask, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const { trigger: triggerReject, isMutating: isRejecting } = useSWRMutation(
    accountId ? ['workflow-tasks', accountId] : null,
    async (_, { arg }: { arg: TasksApiUpdateTaskRequest }) => {
      await fetcher(updateTask, arg)
    },
    { populateCache: false, revalidate: true }
  )

  const approve = async (response: string): Promise<void> => {
    if (accountId && task) {
      await triggerApprove({
        accountId,
        taskId: task.id,
        taskOutcome: {
          success: true,
          reason: response
        }
      })
    }
  }

  const reject = async (reason: string): Promise<void> => {
    if (accountId && task) {
      await triggerReject({
        accountId,
        taskId: task.id,
        taskOutcome: {
          success: false,
          reason
        }
      })
    }
  }

  return {
    approve,
    reject,
    task,
    isApproving,
    isRejecting
  }
}

export default useOrderRequestWorkflowTask
