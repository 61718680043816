import {
  useEffect,
  useRef,
  type HTMLAttributes,
  type ReactElement
} from 'react'
import { type LineItem } from '@amici/myamici-api-client'
import { Button, Form, FormGroup, Table } from 'react-bootstrap'
import {
  Controller,
  type Control,
  type UseFormRegister,
  type FieldErrors,
  type UseFormTrigger,
  type UseFormWatch,
  type UseFormSetValue
} from 'react-hook-form'
import { formatISO } from 'date-fns'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import MaDatePicker from '../../common/components/MaDatePicker'
import { type ReceiveLineItemsFormValues } from './ReceiveLineItemsModal'
import MaTooltip from '../../common/components/MaTooltip'
import MaCheckbox from '../../common/components/MaCheckbox'
import { BsQuestionCircle } from 'react-icons/bs'
import styles from '../assets/scss/ReceiveLineItemsModal.module.scss'

export type FormMode = 'receive' | 'unreceive'

interface ReceiveLineItemFieldsetProps extends HTMLAttributes<HTMLElement> {
  variant: 'item' | 'order'
  mode?: FormMode
  lineItem?: LineItem
  index: number
  quantityDisabled: boolean
  register: UseFormRegister<ReceiveLineItemsFormValues>
  watch: UseFormWatch<ReceiveLineItemsFormValues>
  setValue: UseFormSetValue<ReceiveLineItemsFormValues>
  control: Control<ReceiveLineItemsFormValues>
  errors: FieldErrors<ReceiveLineItemsFormValues>
  container?: HTMLElement
  triggerValidation?: UseFormTrigger<ReceiveLineItemsFormValues>
  onModeChange?: (mode: FormMode) => void
}

function ReceiveLineItemFieldset ({
  variant,
  mode = 'receive',
  index,
  lineItem,
  register,
  watch,
  setValue,
  control,
  errors,
  className,
  quantityDisabled,
  container,
  triggerValidation,
  onModeChange,
  ...props
}: Readonly<ReceiveLineItemFieldsetProps>): ReactElement {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  useEffect(() => {
    void triggerValidation?.()
  }, [mode, triggerValidation])

  const outstandingQuantity =
    (lineItem?.quantity ?? 0) - (lineItem?.quantity_received ?? 0)

  const noOutstandingItems = outstandingQuantity === 0

  const showQuantityControl =
    (!noOutstandingItems && mode === 'receive') || mode === 'unreceive'
  const showDateControl = !noOutstandingItems && mode === 'receive'
  const showToggleUnreceiveButton = noOutstandingItems && mode === 'receive'
  const canEditQuantity =
    !noOutstandingItems &&
    variant === 'item' &&
    mode === 'receive' &&
    (lineItem?.quantity_received ?? 0) > 0
  const canReduceReceivedQuantity = canEditQuantity && !lineItem?.fully_received

  const quantityToReceive = watch(`lineItems.${index}.quantity`)
  const isDateRequired = quantityToReceive > 0
  const isCloseLineEnabled =
    quantityToReceive <
      (lineItem?.quantity ?? 0) - (lineItem?.quantity_received ?? 0) ||
    !!lineItem?.fully_received
  const showCloseLineCheckbox =
    mode === 'receive' && variant === 'item' && !noOutstandingItems

  const handleEditQuantityToggle = (): void => {
    if (canReduceReceivedQuantity) {
      onModeChange?.('unreceive')
    }
  }

  return (
    <div
      ref={containerRef}
      className={classNames(styles['line-item'], className)}
      {...props}
    >
      <h6 className={styles.description}>{lineItem?.product.description}</h6>

      <div className={styles.content}>
        <div className={styles.info}>
          <Table className={styles.table}>
            <tbody>
              <tr>
                <th>{t('receive_order.item.part_number')}</th>
                <td title={lineItem?.product.part_number ?? '-'}>
                  {lineItem?.product.part_number ?? '-'}
                </td>
              </tr>
              <tr>
                <th>{t('receive_order.item.pack_size')}</th>
                <td>
                  <Trans
                    i18nKey="common.product.pack_size_value"
                    values={{
                      count: lineItem?.product.no_of_units,
                      packSizeValue: lineItem?.product.pack_size,
                      packSizeUnit: lineItem?.product.pack_size_unit
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <Table className={styles.table}>
            <tbody>
              <tr>
                <th>{t('receive_order.item.ordered')}</th>
                <td>{lineItem?.quantity ?? '-'}</td>
              </tr>
              <tr>
                <th>{t('receive_order.item.received')}</th>
                <td>
                  <span
                    className={classNames({
                      [styles['toggle-edit']]: canReduceReceivedQuantity
                    })}
                    onClick={handleEditQuantityToggle}
                    title={
                      canReduceReceivedQuantity
                        ? t('receive_order.form.label.reduce_received_quantity')
                        : ''
                    }
                  >
                    {lineItem?.quantity_received ?? '-'}
                  </span>
                </td>
              </tr>
              <tr>
                <th>{t('receive_order.item.outstanding')}</th>
                <td>
                  {lineItem?.fully_received && watch(`lineItems.0.closed`)
                    ? 0
                    : outstandingQuantity}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <section className={styles.controls}>
          <input
            type="hidden"
            {...register(`lineItems.${index}.line_item_id`)}
          />

          <div className={styles.row}>
            {showQuantityControl && (
              <FormGroup
                className={classNames(styles.control, styles.quantity)}
                controlId={`lineItems.${index}.quantity`}
              >
                {mode === 'receive' && (
                  <Form.Label>
                    {t('receive_order.form.label.quantity')}
                  </Form.Label>
                )}
                {mode === 'unreceive' && (
                  <Form.Label className="text-warning">
                    {t('receive_order.form.label.remove')}
                  </Form.Label>
                )}
                <Form.Control
                  type="number"
                  disabled={quantityDisabled}
                  {...register(`lineItems.${index}.quantity`, {
                    onChange: e => {
                      const quantity = e.target.valueAsNumber as number

                      setValue(`lineItems.${index}.quantity`, quantity)
                      if (
                        quantity &&
                        quantity ===
                          (lineItem?.quantity ?? 0) -
                            (lineItem?.quantity_received ?? 0)
                      ) {
                        setValue(
                          `lineItems.${index}.closed`,
                          lineItem?.fully_received
                        )
                      }
                    },
                    valueAsNumber: true,
                    max:
                      mode === 'receive'
                        ? outstandingQuantity
                        : lineItem?.quantity_received,
                    min: 0,
                    required: true
                  })}
                  max={
                    mode === 'receive'
                      ? outstandingQuantity
                      : lineItem?.quantity_received
                  }
                  min={0}
                  isInvalid={!!errors?.lineItems?.[index]?.quantity}
                />
              </FormGroup>
            )}

            {showDateControl && (
              <FormGroup
                className={classNames(styles.control, styles.date)}
                controlId={`lineItems.${index}.date`}
              >
                <Form.Label>{t('receive_order.form.label.date')}</Form.Label>
                <Controller
                  control={control}
                  name={`lineItems.${index}.date`}
                  rules={{
                    required: isDateRequired
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <MaDatePicker
                      container={container}
                      selected={value ? new Date(value) : undefined}
                      onDayBlur={onBlur}
                      disabled={{ after: new Date() }}
                      isInvalid={
                        isDateRequired && !watch(`lineItems.${index}.date`)
                      }
                      onSelect={day => {
                        onChange(
                          day
                            ? formatISO(day, { representation: 'date' })
                            : null
                        )
                      }}
                    />
                  )}
                />
              </FormGroup>
            )}

            {showToggleUnreceiveButton && (
              <FormGroup
                className={classNames(
                  styles.control,
                  styles['toggle-unreceive']
                )}
              >
                <Button
                  variant="outline-primary"
                  className="rounded"
                  onClick={() => {
                    onModeChange?.('unreceive')
                  }}
                >
                  {t('receive_order.form.label.reduce_received_quantity')}
                </Button>
              </FormGroup>
            )}
          </div>

          {showCloseLineCheckbox && (
            <div className={styles.row}>
              <FormGroup className={classNames(styles['close-line-control'])}>
                <Controller
                  control={control}
                  name={`lineItems.${index}.closed`}
                  render={({ field: { onChange, value } }) => (
                    <Form.Label>
                      <MaCheckbox
                        disabled={!isCloseLineEnabled}
                        className={styles['close-checkbox']}
                        checked={isCloseLineEnabled && !!value}
                        onCheckedChange={checked => {
                          onChange(checked)
                          if (lineItem?.fully_received) {
                            setValue(
                              'lineItems.0.quantity',
                              checked ? 0 : outstandingQuantity
                            )
                          }
                        }}
                      />
                      {t('receive_order.form.label.close_line')}
                    </Form.Label>
                  )}
                />

                <MaTooltip
                  container={containerRef}
                  trigger={['hover', 'click']}
                  text={t('receive_order.form.tooltip.close_line')}
                >
                  <BsQuestionCircle
                    size={16}
                    role="button"
                    className="text-primary"
                  />
                </MaTooltip>
              </FormGroup>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default ReceiveLineItemFieldset
