import { RelationType, type Task } from '@amici/myamici-workflow-client'
import useWorkflowTasks from './useWorkflowTasks'
import { useTranslation } from 'react-i18next'

interface UseWorkflowTasksListHook {
  tasks?: Task[]
  filter: (filter: string, task: Task) => boolean
}

function useWorkflowTasksList (): UseWorkflowTasksListHook {
  const { t } = useTranslation()
  const { tasks } = useWorkflowTasks({})

  const filter = (filter: string, task: Task): boolean => {
    const { reference, value, currency, requestedBy } =
      task.relations[RelationType.ORDER_REQUEST]
    const text = t('order_request.task.approval.filter', {
      reference,
      value,
      currency,
      requested_by: requestedBy.name
    })
    return text.toLowerCase().includes(filter.toLowerCase())
  }

  return {
    tasks,
    filter
  }
}

export default useWorkflowTasksList
