import { type Task } from '@amici/myamici-api-client'
import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import { useTranslation } from 'react-i18next'
import { UserPermission } from '../../common/types/user-permission'

interface UsePurchaseOrderTasksHook {
  tasks?: Task[]
  isLoading: boolean
  filter: (filter: string, task: Task) => boolean
}

function usePurchaseOrderTasks (): UsePurchaseOrderTasksHook {
  const {
    tasksApi: { getPage },
    fetcher
  } = useApi()
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { data: tasks, isLoading } = useSWR(
    activeAccount?.accountId &&
      !activeAccount.permissions.includes(UserPermission.HasNextGenPurchasing)
      ? ['tasks', activeAccount.accountId]
      : null,
    async () =>
      await fetcher(getPage, { size: 0, accountId: activeAccount?.accountId })
  )

  const filter = (filter: string, task: Task): boolean => {
    const { type, description, reference, created_by: createdBy, values } = task
    const searchSource = `${t(`task.card.title.${type}`)} ${description ?? ''} ${t(
      `task.card.text.${type}`,
      {
        created_by: createdBy?.name,
        ...values
      }
    )}${reference ?? ''}${t('task.card.price', {
      po_value: values?.po_value
    })} ${values?.supplier_name ?? ''}`
    return searchSource.toLowerCase().includes(filter.toLowerCase())
  }

  return {
    tasks: tasks?.content,
    isLoading,
    filter
  }
}

export default usePurchaseOrderTasks
