import { type ReactElement } from 'react'
import { type OrderResource } from '@amici/myamici-api-client'

function PurchaseOrderReference ({
  order
}: Readonly<{
  order: OrderResource
}>): ReactElement {
  return (
    <>
      {order.consolidation_reference ? (
        <>
          {order.consolidation_reference} ({order.reference})
        </>
      ) : (
        <>{order.reference}</>
      )}
    </>
  )
}
export default PurchaseOrderReference
