export const OrderHistoryEventType = {
  ORDER_CHANGED: 'order_changed',
  LINE_ITEM_CREATED: 'line_item_created',
  LINE_ITEM_CHANGED: 'line_item_changed',
  LINE_ITEM_RECEIVED: 'line_item_received',
  LINE_ITEM_UNRECEIVED: 'line_item_unreceived',
  UPDATE_SPEND_CATEGORY: 'update_spend_category',
  EDIT_ORDER_LINE_ITEM_NOTES: 'edit_order_line_item_notes',
  ORDER_LINE_ITEM_CLOSED_OR_REOPENED: 'order_line_item_closed_or_reopened'
} as const
