import { useCallback, useEffect, type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaConfirm from '../../common/components/MaConfirm'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestSuppliers from '../hooks/useOrderRequestSuppliers'
import {
  OrderRequestSupplierOrderMethodEnum,
  type Supplier,
  SupplierOrderMethodEnum,
  type OrderRequestLineItem,
  type OrderRequestSupplier
} from '@amici/myamici-api-client'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useAccounts from '../../common/hooks/useAccounts'
import { UserModule } from '../../common/types/user-module'
import styles from '../assets/scss/OrderRequestEditOrderMethodModal.module.scss'

export interface OrderRequestEditOrderMethodModalProps {
  orderRequestId: string
  lineItems: OrderRequestLineItem[]
  show: boolean
  onClose: () => void
}

function OrderRequestEditOrderMethodModal ({
  orderRequestId,
  lineItems,
  show,
  onClose
}: Readonly<OrderRequestEditOrderMethodModalProps>): ReactElement {
  const { t } = useTranslation()
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)
  const { trackEvent } = useOrderRequestTrackingEvent(orderRequestId)
  const { activeAccount } = useAccounts()
  const { showToastMessage } = useToastNotification()
  const { suppliers, getSupplierItems, getSupplierCurrencies } =
    useOrderRequestSummary(lineItems)
  const { isLoading, handleUpdate, getOrderMethod, supplierDetails } =
    useOrderRequestSuppliers(orderRequestId)

  const defaultValues = suppliers.reduce<
    Record<string, OrderRequestSupplierOrderMethodEnum>
  >(
    (methods, supplier) => ({
      ...methods,
      [supplier.id as string]: getOrderMethod(
        supplier
      ) as OrderRequestSupplierOrderMethodEnum
    }),
    {}
  )
  const { control, getValues, reset } = useForm({
    mode: 'onChange',
    values: defaultValues,
    defaultValues
  })

  useEffect(() => {
    if (!show) {
      reset()
    }
  }, [reset, show])

  const handleConfirm = (): void => {
    const supplierDetailsToUpdate = Object.keys(getValues()).map(
      (supplierId): OrderRequestSupplier => {
        const existingOrderRequestSupplier = supplierDetails.find(
          supplier => supplier.supplierId === supplierId
        )

        return {
          supplierId,
          ...existingOrderRequestSupplier,
          order_method: getValues(supplierId)
        }
      }
    )

    handleUpdate(supplierDetailsToUpdate)
      .then(() => {
        void refreshHistory()
      })
      .catch(() => {
        showToastMessage('danger', t('order_request.edit_order_method.error'))
      })

    trackEvent('update_order_request_order_methods')
    onClose()
  }
  const handleClose = (): void => {
    trackEvent('close_edit_order_methods_modal')
    onClose()
  }

  const showDirectOption = useCallback(
    (supplier: Supplier): boolean =>
      supplier.order_method !== SupplierOrderMethodEnum.MANUAL &&
      getSupplierItems(supplier, getSupplierCurrencies(supplier)[0]).every(
        item => !item.line_item.product.is_manual_order
      ),
    [getSupplierCurrencies, getSupplierItems]
  )

  const showConsolidationOption = useCallback(
    (supplier: Supplier): boolean =>
      !!(
        showDirectOption(supplier) &&
        supplier.consolidate_orders &&
        activeAccount?.modules.includes(UserModule.HasConsolidationModule)
      ),
    [activeAccount?.modules, showDirectOption]
  )

  return (
    <MaConfirm
      size="lg"
      className={styles.modal}
      title={t('order_request.edit_order_method.title')}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      show={show}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <p>
            <Trans
              i18nKey={'order_request.edit_order_method.instructions'}
              values={{
                count: suppliers?.length,
                supplier_name: suppliers?.[0]?.name
              }}
            />
          </p>

          <Form>
            {suppliers?.map(supplier => (
              <Form.Group
                key={supplier.id}
                className={styles['form-group']}
                controlId={`${supplier.name}-label`}
              >
                {suppliers.length > 1 && (
                  <Form.Label column={true}>{supplier.name}</Form.Label>
                )}
                <Controller
                  name={supplier.id as string}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MaSelect
                      aria-labelledby={`${supplier.name}-label`}
                      value={value}
                      onValueChange={value => {
                        onChange(value)
                      }}
                    >
                      <MaSelectItem
                        value={OrderRequestSupplierOrderMethodEnum.MANUAL}
                      >
                        {t('order_request.order_method', {
                          context: OrderRequestSupplierOrderMethodEnum.MANUAL
                        })}
                      </MaSelectItem>
                      {showDirectOption(supplier) && (
                        <MaSelectItem
                          value={OrderRequestSupplierOrderMethodEnum.DIRECT}
                        >
                          {t('order_request.order_method', {
                            context: OrderRequestSupplierOrderMethodEnum.DIRECT
                          })}
                        </MaSelectItem>
                      )}
                      {showConsolidationOption(supplier) && (
                        <MaSelectItem
                          value={
                            OrderRequestSupplierOrderMethodEnum.CONSOLIDATED
                          }
                        >
                          {t('order_request.order_method', {
                            context:
                              OrderRequestSupplierOrderMethodEnum.CONSOLIDATED
                          })}
                        </MaSelectItem>
                      )}
                    </MaSelect>
                  )}
                />
              </Form.Group>
            ))}
          </Form>
        </>
      )}
    </MaConfirm>
  )
}

export default OrderRequestEditOrderMethodModal
