import i18n from 'i18next'

interface UseCurrencyHook {
  currencySymbol: (currency: string) => string | undefined
}

function useCurrency (): UseCurrencyHook {
  const currencySymbol = (currency: string): string | undefined =>
    Intl.NumberFormat(i18n.resolvedLanguage, {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency
    })
      .formatToParts(0)
      .find(part => part.type === 'currency')?.value

  return {
    currencySymbol
  }
}

export default useCurrency
