import useSWR from 'swr'
import {
  type HistoryEvent,
  type OrderRequestHistory
} from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import useOrderRequestWorkflowHistory from './useOrderRequestWorkflowHistory'

interface UseOrderRequestHistoryHook {
  historyEntries: HistoryEvent[]
  mutate: () => Promise<void>
  isLoading: boolean
}

function useOrderRequestHistory (
  orderRequestId = ''
): UseOrderRequestHistoryHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: { getOrderRequestHistory },
    fetcher
  } = useApi()
  const {
    historyEntries: workflowHistoryEntries,
    isLoading: isLoadingWorkflowHistory,
    mutate: mutateWorkflowHistory
  } = useOrderRequestWorkflowHistory(orderRequestId)

  const accountId = activeAccount?.accountId ?? ''

  const {
    data: orderRequestHistory,
    isLoading: isLoadingOrderRequestHistory,
    mutate: mutateOrderRequestHistory
  } = useSWR<OrderRequestHistory, Error>(
    accountId && orderRequestId
      ? ['order-request-history', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getOrderRequestHistory, { orderRequestId, accountId })
  )

  let historyEntries = orderRequestHistory?.content ?? []
  historyEntries = historyEntries.concat(workflowHistoryEntries ?? [])
  historyEntries.sort((a, b) => (a.date > b.date ? -1 : 1))

  return {
    historyEntries,
    mutate: async () => {
      await mutateOrderRequestHistory()
      await mutateWorkflowHistory()
    },
    isLoading: isLoadingOrderRequestHistory || isLoadingWorkflowHistory
  }
}

export default useOrderRequestHistory
