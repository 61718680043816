import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum,
  type OrderRequestLineItem,
  type SpendCategory
} from '@amici/myamici-api-client'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type ViewType,
  ViewTypeValue
} from '../types/order-request-details-summary-view-type'
import useAccounts from '../../common/hooks/useAccounts'
import useLineItemSelection from '../hooks/useLineItemSelection'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaPanel from '../../common/components/MaPanel'
import OrderRequestSpendPanelHeaderControls from './OrderRequestSpendPanelHeaderControls'
import OrderRequestSpendGroupedView from './OrderRequestSpendGroupedView'
import OrderRequestEditLineItemModal from './OrderRequestEditLineItemModal'
import OrderRequestRemoveLineItemModal from './OrderRequestRemoveLineItemModal'
import OrderRequestAddLineItemModal from './OrderRequestAddLineItemModal'
import OrderRequestSpendTable from './OrderRequestSpendTable'
import OrderRequestGroupEditSpendCategoriesModal from './OrderRequestGroupEditSpendCategoriesModal'
import styles from '../assets/scss/OrderRequest.module.scss'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import OrderRequestEditCustomOrderRefsModal from './OrderRequestEditCustomOrderRefsModal'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import ReactGA from 'react-ga4'
import OrderRequestEditOrderMethodModal from './OrderRequestEditOrderMethodModal'

const FULL_VIEW_MIN_WIDTH_PX = 705
const { NEW, WITHDRAWN, REJECTED } = OrderRequestAllOfOrderStatusEnum

function OrderRequestSpendPanel ({
  orderRequest
}: Readonly<{
  orderRequest: OrderRequest
}>): ReactNode {
  const { t } = useTranslation()
  const { activeAccount, accountProfile } = useAccounts()
  const { isFinanceUser, isRequestedByUser, isCreatedByUser } =
    useOrderRequestPermissions({
      orderRequest,
      account: activeAccount
    })
  const {
    lineItems,
    linesWithMissingSpendCategories,
    linesWithInactiveSpendCategories,
    isLoading,
    groupUpdateLineItems
  } = useOrderRequestLineItems(orderRequest.id)
  const [activeView, setActiveView] = useState<ViewType>(ViewTypeValue.grouped)
  const { supplierIds } = useOrderRequestSummary(lineItems)
  const {
    selectedLineItemIds,
    selectedSuppliers,
    handleItemSelectedChange,
    handleToggleSelectionBySupplier
  } = useLineItemSelection(lineItems)
  const { data: spendCategoryFields } = useSpendCategories(true)
  const { showToastMessage } = useToastNotification()
  const [expandedSupplierIds, setExpandedSupplierIds] = useState<string[]>([])
  const [showAddLineItemModal, setShowAddLineItemModal] = useState(false)
  const [showGroupEditModal, setShowGroupEditModal] = useState(false)
  const [showCustomPoRefsModal, setShowCustomPoRefsModal] = useState(false)
  const [showEditOrderMethodModal, setShowEditOrderMethodModal] =
    useState(false)
  const [lineItemToEdit, setLineItemToEdit] = useState<
    OrderRequestLineItem | undefined
  >()
  const [lineItemToRemove, setLineItemToRemove] = useState<
    OrderRequestLineItem | undefined
  >()

  const ref = useRef(null)
  const compactView = !useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  // Expanded sections state cleanup
  useEffect(() => {
    expandedSupplierIds.forEach(id => {
      if (!supplierIds.includes(id)) {
        setExpandedSupplierIds(expandedIds =>
          expandedIds.filter(expandedId => expandedId !== id)
        )
      }
    })
  }, [expandedSupplierIds, supplierIds])

  const orderRequestStatus = orderRequest.order_status
  const statusAllowsChanges =
    orderRequestStatus === NEW ||
    orderRequestStatus === REJECTED ||
    orderRequestStatus === WITHDRAWN
  const canChange =
    (isRequestedByUser || isCreatedByUser || isFinanceUser) &&
    statusAllowsChanges
  const canGroupEdit =
    (isRequestedByUser || isCreatedByUser) &&
    statusAllowsChanges &&
    (spendCategoryFields?.length ?? 0) > 0
  const canSetCustomOrderRefs =
    canChange &&
    lineItems.length > 0 &&
    (accountProfile?.client?.has_custom_order_refs ?? false)
  const canEditOrderMethods =
    statusAllowsChanges &&
    lineItems.length > 0 &&
    (isRequestedByUser || isCreatedByUser)
  const itemsSelected = selectedLineItemIds.length > 0

  const itemsRequiringUpdates = [
    ...new Set([
      ...linesWithMissingSpendCategories,
      ...linesWithInactiveSpendCategories
    ])
  ]

  const handleToggleExpandedSupplier = (supplierId: string): void => {
    let action = ''
    if (expandedSupplierIds.includes(supplierId)) {
      setExpandedSupplierIds(
        expandedSupplierIds.filter(id => id !== supplierId)
      )
      action = 'collapse_supplier'
    } else {
      setExpandedSupplierIds([...expandedSupplierIds, supplierId])
      action = 'expand_supplier'
    }
    ReactGA.event('action_item', {
      item_list_id: 'order_request',
      supplier_id: supplierId,
      action
    })
  }

  const handleEditFormOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToEdit(lineItem)
    trackEvent('open_edit_line_item_modal')
  }

  const handleEditFormClose = (): void => {
    setLineItemToEdit(undefined)
  }

  const handleRemoveModalOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToRemove(lineItem)
    trackEvent('open_remove_line_item_modal')
  }

  const handleRemoveModalClose = (): void => {
    setLineItemToRemove(undefined)
  }

  const handleGroupEditSave = async (
    newSpendCategories: SpendCategory[]
  ): Promise<void> => {
    setShowGroupEditModal(false)

    const lineItemsToUpdate = lineItems.filter(lineItem =>
      selectedLineItemIds.includes(lineItem.line_item.id)
    )
    const modifiedLineItems = lineItemsToUpdate.map(lineItem => ({
      ...lineItem,
      line_item: {
        ...lineItem.line_item,
        issues: [],
        spend_categories: [
          ...(lineItem.line_item.spend_categories ?? []).filter(
            existingSpendCategory =>
              !newSpendCategories.find(
                newSpendCategory =>
                  newSpendCategory.field_id === existingSpendCategory.field_id
              )
          ),
          ...newSpendCategories
        ].toSorted((a, b) => a.field_id.localeCompare(b.field_id))
      }
    }))

    try {
      trackEvent('update_spend_categories_group_edit')
      await groupUpdateLineItems(modifiedLineItems)
    } catch {
      showToastMessage('danger', t('order_request.line_items.group_edit.error'))
    }
  }

  const getAssignedFieldIds = (): string[] => {
    const assignedFieldIds: string[] = []
    lineItems.forEach(lineItem => {
      lineItem.line_item.spend_categories?.forEach(spendCategory => {
        if (
          !assignedFieldIds.find(
            assignedFieldId => assignedFieldId === spendCategory.field_id
          )
        ) {
          assignedFieldIds.push(spendCategory.field_id)
        }
      })
    })
    return assignedFieldIds
  }

  if (!orderRequest || isLoading) {
    return null
  }

  return (
    <>
      <MaPanel className={styles['spend-panel']} ref={ref}>
        <MaPanel.Header className={styles['spend-panel-header']}>
          <h4>{t('order_request.spend.title')}</h4>

          <OrderRequestSpendPanelHeaderControls
            compactView={compactView}
            activeView={activeView}
            allCollapsed={expandedSupplierIds.length === 0}
            canAdd={canChange}
            canGroupEdit={canGroupEdit}
            canSetCustomPoRefs={canSetCustomOrderRefs}
            canEditOrderMethods={canEditOrderMethods}
            itemsSelected={itemsSelected}
            onAddNew={() => {
              trackEvent('open_add_product_modal')
              setShowAddLineItemModal(true)
            }}
            onCollapseAll={() => {
              trackEvent('collapse_all')
              setExpandedSupplierIds([])
            }}
            onExpandAll={() => {
              trackEvent('expand_all')
              setExpandedSupplierIds(supplierIds)
            }}
            onViewChange={view => {
              setActiveView(view)
              trackEvent(
                view === ViewTypeValue.grouped ? 'card_view' : 'table_view'
              )
            }}
            onGroupEdit={() => {
              trackEvent('open_edit_spend_categories_modal')
              setShowGroupEditModal(true)
            }}
            onSetCustomPoRefs={() => {
              trackEvent('open_edit_custom_order_refs_modal')
              setShowCustomPoRefsModal(true)
            }}
            onEditOrderMethods={() => {
              trackEvent('open_edit_order_methods_modal')
              setShowEditOrderMethodModal(true)
            }}
          />
        </MaPanel.Header>

        <MaPanel.Body className={styles['spend-panel-body']}>
          {(compactView || activeView === ViewTypeValue.grouped) && (
            <OrderRequestSpendGroupedView
              orderRequest={orderRequest}
              lineItems={lineItems}
              lineItemsWithMissingSpendCategories={
                linesWithMissingSpendCategories
              }
              lineItemsWithInactiveSpendCategories={
                linesWithInactiveSpendCategories
              }
              expandedSupplierIds={expandedSupplierIds}
              compactView={compactView}
              canChange={canChange}
              selectable={canGroupEdit}
              selectedSuppliers={selectedSuppliers}
              selectedLineItemIds={selectedLineItemIds}
              onToggleExpanded={handleToggleExpandedSupplier}
              onEdit={handleEditFormOpen}
              onRemove={handleRemoveModalOpen}
              onItemSelectedChange={handleItemSelectedChange}
              onToggleSelectionBySupplier={handleToggleSelectionBySupplier}
            />
          )}
          {!compactView && activeView === ViewTypeValue.table && (
            <OrderRequestSpendTable
              orderRequestId={orderRequest.id}
              orderRequestLineItems={lineItems}
              itemsRequiringUpdates={itemsRequiringUpdates}
              onEdit={handleEditFormOpen}
              onRemove={handleRemoveModalOpen}
              includeActions={canChange}
              selectable={canGroupEdit}
              selectedSuppliers={selectedSuppliers}
              selectedLineItemIds={selectedLineItemIds}
              onItemSelectedChange={handleItemSelectedChange}
              onToggleSelectionBySupplier={handleToggleSelectionBySupplier}
            />
          )}
        </MaPanel.Body>
      </MaPanel>

      <OrderRequestAddLineItemModal
        orderRequestId={orderRequest.id}
        show={showAddLineItemModal}
        onClose={() => {
          setShowAddLineItemModal(false)
          trackEvent('close_add_product_modal')
        }}
      />

      <OrderRequestEditLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToEdit}
        onClose={() => {
          handleEditFormClose()
          trackEvent('close_edit_line_item_modal')
        }}
      />

      <OrderRequestRemoveLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToRemove}
        onClose={() => {
          handleRemoveModalClose()
          trackEvent('close_remove_line_item_modal')
        }}
      />

      <OrderRequestGroupEditSpendCategoriesModal
        assignedFieldIds={getAssignedFieldIds()}
        show={showGroupEditModal}
        disabled={false}
        onCancel={() => {
          setShowGroupEditModal(false)
          trackEvent('close_edit_spend_categories_modal')
        }}
        onSave={handleGroupEditSave}
      />

      <OrderRequestEditCustomOrderRefsModal
        show={showCustomPoRefsModal}
        orderRequestId={orderRequest.id}
        lineItems={lineItems}
        onClose={() => {
          setShowCustomPoRefsModal(false)
          trackEvent('close_edit_custom_order_refs_modal')
        }}
      />

      <OrderRequestEditOrderMethodModal
        show={showEditOrderMethodModal}
        orderRequestId={orderRequest.id}
        lineItems={lineItems}
        onClose={() => {
          setShowEditOrderMethodModal(false)
        }}
      />
    </>
  )
}

export default OrderRequestSpendPanel
