import MaModal from '../../common/components/MaModal'
import {
  BaseStateTypeEnum,
  type StateExecution,
  type StateExecutionTask
} from '@amici/myamici-workflow-client'
import { type ReactElement } from 'react'
import useAllAccounts from '../../common/hooks/useAllAccounts'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { type Account } from '@amici/myamici-api-client'
import styles from '../assets/scss/WorkflowStateExecutionDetailsModal.module.scss'
import { type StateExecutionDetails } from '../models/StateExecutionDetails'
import { BsFillCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import classNames from 'classnames'

interface TaskListItemProps {
  task: StateExecutionTask
  account?: Account
}

function TaskListItem ({
  task,
  account
}: Readonly<TaskListItemProps>): ReactElement {
  const { t } = useTranslation()
  const className = account?.name ? undefined : styles.unknown
  const outcomeClassName = task.outcome && styles.outcome
  return (
    <li className={classNames(className, outcomeClassName)}>
      <span>
        {account?.name ?? t('order_request.workflow_status.unknown_account')}
      </span>
      {task.outcome &&
        (task.outcome.success ? (
          <BsFillCheckCircleFill size={16} className={styles.success} />
        ) : (
          <BsXCircleFill size={16} className={styles.failure} />
        ))}
    </li>
  )
}

function StartStateBody ({
  stateExecution
}: Readonly<{ stateExecution: StateExecution }>): ReactElement {
  const { t } = useTranslation()
  return (
    <div>
      {t('order_request.workflow_status.submitted_for_approval', {
        date: new Date(stateExecution.entered)
      })}
    </div>
  )
}

function EndStateBody ({
  stateExecution
}: Readonly<{ stateExecution: StateExecution }>): ReactElement {
  const { t } = useTranslation()
  return (
    <div>
      {t('order_request.workflow_status.fully_approved', {
        date: new Date(stateExecution.entered)
      })}
    </div>
  )
}

interface TaskListProps {
  tasks: StateExecutionTask[]
  accounts: Account[]
}

const taskComparator = (
  a: StateExecutionTask,
  b: StateExecutionTask
): number => {
  let result: number
  if (a.outcome && !b.outcome) {
    result = -1
  } else if (!a.outcome && b.outcome) {
    result = 1
  } else {
    result = 0
  }
  return result
}

function TaskList ({ tasks, accounts }: Readonly<TaskListProps>): ReactElement {
  return (
    <div className={styles.tasks}>
      <ul>
        {tasks.map(task => (
          <TaskListItem
            key={task.id}
            task={task}
            account={accounts.find(account => account.id === task.assignee_id)}
          />
        ))}
      </ul>
    </div>
  )
}

function ActionStateBody ({
  stateExecution,
  accounts
}: Readonly<{
  stateExecution?: StateExecution
  accounts: Account[]
}>): ReactElement {
  const { t } = useTranslation()
  let message: string
  let filteredTasks: StateExecutionTask[] | undefined
  if (stateExecution) {
    const taskAssigneeIds = new Set()
    const sortedTasks = stateExecution.tasks?.toSorted(taskComparator)
    filteredTasks = sortedTasks?.filter(
      task =>
        !taskAssigneeIds.has(task.assignee_id) &&
        taskAssigneeIds.add(task.assignee_id)
    )
    if (stateExecution.exited) {
      if (filteredTasks?.find(task => task.outcome?.success)) {
        message = t('order_request.workflow_status.approved_by')
      } else {
        message = t('order_request.workflow_status.rejected_by')
      }
    } else {
      message = t('order_request.workflow_status.approval_required', {
        count: filteredTasks?.length
      })
    }
  } else {
    message = t('order_request.workflow_status.approval_will_be_required')
  }
  return (
    <div>
      <p>{message}</p>
      {filteredTasks?.length && (
        <TaskList tasks={filteredTasks} accounts={accounts} />
      )}
    </div>
  )
}

interface WorkflowStateExecutionDetailsModalBodyProps {
  stateExecutionDetails: StateExecutionDetails
  accounts: Account[]
}

function WorkflowStateExecutionDetailsModalBody ({
  stateExecutionDetails,
  accounts
}: Readonly<WorkflowStateExecutionDetailsModalBodyProps>): ReactElement {
  let details: ReactElement | null
  const stateExecution = stateExecutionDetails.stateExecution as StateExecution
  switch (stateExecutionDetails.state.type) {
    case BaseStateTypeEnum.START:
      details = <StartStateBody stateExecution={stateExecution} />
      break
    case BaseStateTypeEnum.END:
      details = <EndStateBody stateExecution={stateExecution} />
      break
    case BaseStateTypeEnum.ACTION:
      details = (
        <ActionStateBody stateExecution={stateExecution} accounts={accounts} />
      )
      break
    default:
      details = null
  }

  return <div className={styles.body}>{details}</div>
}

interface WorkflowStateExecutionDetailsModalProps {
  stateExecutionDetails: StateExecutionDetails
  onClose: () => void
}

function WorkflowStateExecutionDetailsModal ({
  stateExecutionDetails,
  onClose
}: Readonly<WorkflowStateExecutionDetailsModalProps>): ReactElement {
  const { allAccounts, isLoading } = useAllAccounts()

  const title = <span role="heading">{stateExecutionDetails.state.name}</span>
  return (
    <MaModal
      show={true}
      onClose={onClose}
      title={title}
      dialogClassName={styles.dialog}
      centered
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        allAccounts?.content && (
          <WorkflowStateExecutionDetailsModalBody
            stateExecutionDetails={stateExecutionDetails}
            accounts={allAccounts.content}
          />
        )
      )}
    </MaModal>
  )
}

export default WorkflowStateExecutionDetailsModal
