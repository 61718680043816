import { type ReactElement } from 'react'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import { useTranslation } from 'react-i18next'

export interface PurchaseOrderLineItemActionMenuProps {
  lineItem: OrderLineItemResource
  canEdit?: boolean
  canAddNote?: boolean
  onItemEdit?: (lineItem: OrderLineItemResource) => void
  onNoteAdd?: (lineItem: OrderLineItemResource) => void
}

function PurchaseOrderLineItemActionMenu ({
  lineItem,
  canEdit,
  canAddNote,
  onNoteAdd,
  onItemEdit
}: Readonly<PurchaseOrderLineItemActionMenuProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <MaActionMenu aria-label={t('purchase_order.order_item.actions')}>
      {canAddNote && onNoteAdd && (
        <MaActionMenuItem
          onClick={() => {
            onNoteAdd?.(lineItem)
          }}
        >
          {t('purchase_order.order_item.actions.add_note')}
        </MaActionMenuItem>
      )}
      {canEdit && onItemEdit && (
        <MaActionMenuItem
          onClick={() => {
            onItemEdit?.(lineItem)
          }}
        >
          {t('purchase_order.order_item.actions.edit')}
        </MaActionMenuItem>
      )}
    </MaActionMenu>
  )
}

export default PurchaseOrderLineItemActionMenu
