import { type RouteObject } from 'react-router-dom'
import { UserPermission } from '../common/types/user-permission'
import ProtectedRoute from '../common/components/ProtectedRoute'
import SpendCategories from '../spend-categories/pages/SpendCategories'
import Workflows from '../workflow/pages/Workflows'
import WorkflowGroups from '../workflow/pages/WorkflowGroups'

const purchasingRoutes: RouteObject[] = [
  {
    path: 'settings',
    children: [
      {
        path: 'spend-categories',
        element: (
          <ProtectedRoute
            requiredPermissions={[
              UserPermission.IsFinance,
              UserPermission.HasNextGenPurchasing
            ]}
          >
            <SpendCategories />
          </ProtectedRoute>
        )
      },
      {
        path: 'workflows',
        element: (
          <ProtectedRoute
            requiredPermissions={[
              UserPermission.IsPurchasingAdmin,
              UserPermission.HasNextGenPurchasing
            ]}
          >
            <Workflows />
          </ProtectedRoute>
        )
      },
      {
        path: 'workflow-groups',
        element: (
          <ProtectedRoute
            requiredPermissions={[
              UserPermission.IsContractManagerUser,
              UserPermission.HasNextGenPurchasing
            ]}
          >
            <WorkflowGroups />
          </ProtectedRoute>
        )
      }
    ]
  }
]

export default purchasingRoutes
