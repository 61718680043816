import { type ReactElement, type ReactNode, useId, useState } from 'react'
import { format } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import {
  type LineItem,
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum,
  OrderRequestBasePriorityEnum,
  type OrderRequestLineItem,
  ProductStatusEnum,
  type SpendCategoryField
} from '@amici/myamici-api-client'
import MaPanel from '../../common/components/MaPanel'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import styles from '../assets/scss/OrderRequest.module.scss'
import useAccounts from '../../common/hooks/useAccounts'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import MaAlert from '../../common/components/MaAlert'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'
import { Link } from 'react-router-dom'
import { useUpdatingLineItemsState } from './OrderRequestUpdatingLineItemsProvider'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import useWorkflows from '../../workflow/hooks/useWorkflows'
import useOrderRequestWorkflow from '../hooks/useOrderRequestWorkflow'
import useOrderRequestWorkflowTask from '../hooks/useOrderRequestWorkflowTask'
import { RelationType } from '@amici/myamici-workflow-client'
import useOrderRequest from '../hooks/useOrderRequest'
import usePurchasingPermissions from '../../purchasing/hooks/usePurchasingPermissions'

const lineItemSpendCategoriesInvalid = (
  lineItem: LineItem,
  spendCategoryFields: SpendCategoryField[]
): boolean => {
  const spendCategoryFieldsCompleted = spendCategoryFields?.filter(
    spendCategoryField =>
      lineItem.spend_categories?.some(
        spendCategory => spendCategory.field_id === spendCategoryField.id
      )
  )
  return spendCategoryFieldsCompleted?.length !== spendCategoryFields?.length
}

const spendCategoriesValid = (
  lineItems: OrderRequestLineItem[],
  spendCategoryFields: SpendCategoryField[]
): boolean =>
  !lineItems.some(lineItem =>
    lineItemSpendCategoriesInvalid(lineItem.line_item, spendCategoryFields)
  )

function OrderRequestSummaryPanelSubmitFooter ({
  orderRequest
}: Readonly<{
  orderRequest: OrderRequest
}>): ReactElement | undefined {
  const { activeAccount } = useAccounts()
  const { workflow, isLoading: workflowIsLoading } = useWorkflows(
    RelationType.ORDER_REQUEST
  )
  const { create: createWorkflowExecution, isCreating: isSubmitting } =
    useOrderRequestWorkflow(orderRequest.id)
  const { isRequestedByUser, isCreatedByUser } = useOrderRequestPermissions({
    orderRequest,
    account: activeAccount
  })
  const { isPurchasingAdmin } = usePurchasingPermissions(activeAccount)
  const { lineItems } = useOrderRequestLineItems(orderRequest.id)
  const { data, isLoading: spendCategoriesIsLoading } = useSpendCategories(true)
  const { mutate: refreshOrderRequest } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToastMessage } = useToastNotification()
  const { t } = useTranslation()
  const [showValidationError, setShowValidationError] = useState(false)
  const state = useUpdatingLineItemsState()
  const submissionDisabled =
    spendCategoriesIsLoading ||
    workflowIsLoading ||
    lineItems.length === 0 ||
    isSubmitting ||
    (state?.activeDeletionsCount ?? 0) > 0 ||
    (state?.activeEditingIds.length ?? 0) > 0
  const areLineItemsValid = lineItems.every(
    item => item.line_item.product.status === ProductStatusEnum.ACTIVE
  )
  const areSpendCategoriesValid = data && spendCategoriesValid(lineItems, data)

  let validationMessageKey
  if (!workflow) {
    validationMessageKey = 'order_request.status_update.submit.error.workflow'
  } else {
    if (!areSpendCategoriesValid && !areLineItemsValid) {
      validationMessageKey = 'order_request.status_update.submit.error.other'
    } else if (!areLineItemsValid) {
      validationMessageKey =
        'order_request.status_update.submit.error.invalid_product'
    } else {
      validationMessageKey =
        'order_request.status_update.submit.error.spend_categories'
    }
  }

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  if (isRequestedByUser || isCreatedByUser || isPurchasingAdmin) {
    return (
      <>
        <Button
          disabled={submissionDisabled}
          variant="primary"
          className="rounded"
          onClick={() => {
            trackEvent('submit')
            if (areSpendCategoriesValid && areLineItemsValid && workflow) {
              const showSubmitFailureToastMessage = (): string | number =>
                showToastMessage(
                  'danger',
                  t('order_request.status_update.submit.failure', {
                    ref: orderRequest.order_ref
                  })
                )
              void createWorkflowExecution(workflow.id)
                .then(() => {
                  showToastMessage(
                    'dark',
                    t('order_request.status_update.submit.success', {
                      ref: orderRequest.order_ref
                    })
                  )
                  void refreshOrderRequest()
                  void refreshHistory()
                })
                .catch(showSubmitFailureToastMessage)
            } else {
              setShowValidationError(true)
            }
          }}
        >
          <Trans i18nKey="order_request.action.submit" />
        </Button>
        <MaAlert
          variant="error"
          show={showValidationError}
          onClose={() => {
            setShowValidationError(false)
          }}
        >
          <p>
            <Trans i18nKey={validationMessageKey} />
          </p>
        </MaAlert>
      </>
    )
  }
}

function OrderRequestSummaryPanelPendingFooter ({
  orderRequest
}: Readonly<{
  orderRequest: OrderRequest
}>): ReactElement | undefined {
  const { t } = useTranslation()
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const { mutate: refreshOrderRequest } = useOrderRequest(orderRequest.id)
  const { lineItems } = useOrderRequestLineItems(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { mutate: refreshWorkflowExecutions } = useOrderRequestWorkflow(
    orderRequest.id
  )
  const { task, approve, isApproving, reject, isRejecting } =
    useOrderRequestWorkflowTask(orderRequest.id)
  const { showToastMessage } = useToastNotification()
  const [showValidationError, setShowValidationError] = useState(false)

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  const handleError = (i18nKey: string): void => {
    showToastMessage(
      'danger',
      t(i18nKey, {
        ref: orderRequest.order_ref
      })
    )
  }

  const lineItemsValid = lineItems.every(
    item => item.line_item.product.status === ProductStatusEnum.ACTIVE
  )

  return task ? (
    <>
      <Button
        variant="danger"
        className="rounded"
        onClick={() => {
          setShowRejectDialog(true)
          trackEvent('open_reject_order_request_modal')
        }}
      >
        <Trans i18nKey="order_request.action.reject" />
      </Button>
      <Button
        className="rounded"
        onClick={() => {
          if (lineItemsValid) {
            setShowApproveDialog(true)
            trackEvent('open_approve_order_request_modal')
          } else {
            setShowValidationError(true)
          }
        }}
      >
        <Trans i18nKey="order_request.action.approve" />
      </Button>
      <OrderRequestStatusUpdateModal
        show={showApproveDialog}
        onClose={() => {
          setShowApproveDialog(false)
          trackEvent('close_approve_order_request_modal')
        }}
        onConfirm={approvalResponse => {
          trackEvent('approve')
          void approve(approvalResponse)
            .then(() => {
              void refreshOrderRequest()
              void refreshHistory()
              void refreshWorkflowExecutions()
            })
            .catch(() => {
              handleError('order_request.status_update.approve.failure')
            })
            .finally(() => {
              setShowApproveDialog(false)
            })
        }}
        disabled={isApproving}
        confirmLabel={t('order_request.action.approve')}
        title={t('order_request.status_update.approve.title', {
          ref: orderRequest.order_ref
        })}
        reasonLabel={t(
          'order_request.status_update.approve.approval_response.label'
        )}
      />
      <OrderRequestStatusUpdateModal
        show={showRejectDialog}
        onClose={() => {
          setShowRejectDialog(false)
          trackEvent('close_reject_order_request_modal')
        }}
        onConfirm={reason => {
          trackEvent('reject')
          void reject(reason)
            .then(() => {
              void refreshOrderRequest()
              void refreshHistory()
              void refreshWorkflowExecutions()
            })
            .catch(() => {
              handleError('order_request.status_update.reject.failure')
            })
            .finally(() => {
              setShowRejectDialog(false)
            })
        }}
        disabled={isRejecting}
        confirmLabel={t('order_request.action.reject')}
        title={t('order_request.status_update.reject.title', {
          ref: orderRequest.order_ref
        })}
        reasonLabel={t(
          'order_request.status_update.reject.rejection_reason.label'
        )}
        reasonRequired
      />
      <MaAlert
        variant="error"
        show={showValidationError}
        onClose={() => {
          setShowValidationError(false)
        }}
      >
        <p>
          <Trans
            i18nKey={
              'order_request.status_update.approve.error.invalid_product'
            }
          />
        </p>
      </MaAlert>
    </>
  ) : undefined
}

function OrderRequestSummaryPanelFooter ({
  orderRequest
}: Readonly<{
  orderRequest: OrderRequest
}>): ReactElement {
  let footerContent: ReactElement | undefined
  switch (orderRequest.order_status) {
    case OrderRequestAllOfOrderStatusEnum.NEW:
    case OrderRequestAllOfOrderStatusEnum.REJECTED:
    case OrderRequestAllOfOrderStatusEnum.WITHDRAWN: {
      footerContent = (
        <OrderRequestSummaryPanelSubmitFooter orderRequest={orderRequest} />
      )
      break
    }
    case OrderRequestAllOfOrderStatusEnum.PENDING:
      footerContent = (
        <OrderRequestSummaryPanelPendingFooter orderRequest={orderRequest} />
      )
      break
    default:
      break
  }
  return (
    <>
      {footerContent && (
        <MaPanel.Footer className={styles['summary-panel-footer']}>
          {footerContent}
        </MaPanel.Footer>
      )}
    </>
  )
}

interface OrderRequestSummaryProps {
  orderRequest: OrderRequest
}

function OrderRequestSummaryPanel ({
  orderRequest
}: Readonly<OrderRequestSummaryProps>): ReactNode {
  const { t } = useTranslation()
  const {
    count,
    total,
    currency,
    lineItems,
    isLoading: isLoadingLineItems
  } = useOrderRequestLineItems(orderRequest?.id)
  const { suppliers } = useOrderRequestSummary(lineItems)
  const id = useId()

  if (isLoadingLineItems) {
    return null
  }

  const hasOrderReferences =
    orderRequest.order_references && orderRequest.order_references.length > 0

  const hideTotal = lineItems.every(
    lineItem => lineItem.line_item.product.status === ProductStatusEnum.INACTIVE
  )

  return (
    <MaPanel className={styles['summary-panel']}>
      <MaPanel.Header className={styles['summary-panel-header']}>
        <h4>
          {t('order_request.summary.title')}{' '}
          {orderRequest.priority &&
            orderRequest.priority !== OrderRequestBasePriorityEnum.NONE && (
              <span
                className={
                  styles[`priority-${orderRequest.priority.toLowerCase()}`]
                }
              >
                {t(
                  `order_request.priority.${orderRequest.priority.toLowerCase()}`
                )}
              </span>
            )}
        </h4>
      </MaPanel.Header>

      <MaPanel.Body className={styles['summary-panel-body']}>
        <p className={styles['summary-message']}>
          {orderRequest?.confidential && (
            <span className={styles.confidential}>
              {t('order_request.type.confidential')}
            </span>
          )}
          <Trans
            i18nKey={'order_request.summary.message.requested_by'}
            values={{
              requestedBy: orderRequest.requested_by?.name,
              createdOn: format(
                new Date(orderRequest.created_date),
                'do MMMM yyyy'
              )
            }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.items'}
            values={{ count }}
          />{' '}
          <Trans
            i18nKey={'order_request.summary.message.suppliers'}
            values={{ supplier: suppliers?.[0]?.name, count: suppliers.length }}
          />{' '}
          {hideTotal ? (
            <Trans
              i18nKey={'order_request.summary.message.total_cost.inactive'}
              values={{ price: total, currency }}
            />
          ) : (
            <Trans
              i18nKey={'order_request.summary.message.total_cost'}
              values={{ price: total, currency }}
            />
          )}
          {orderRequest.required_by_date && (
            <>
              {' '}
              <Trans
                i18nKey={'order_request.summary.message.required_by_date'}
                values={{
                  requestedBy: orderRequest.required_by_date,
                  requiredByDate: format(
                    new Date(orderRequest.required_by_date),
                    'do MMMM yyyy'
                  )
                }}
              />
            </>
          )}
        </p>

        {orderRequest.reason && (
          <div className={styles.reason}>
            <h6>{t('order_request.summary.reason.title')}</h6>
            <p>{orderRequest.reason}</p>
          </div>
        )}
        {hasOrderReferences && (
          <div className={styles['purchase-order-links']}>
            <label id={id}>{t('order_request.summary.po.created.label')}</label>
            <div aria-labelledby={id}>
              {orderRequest.order_references?.map(orderReference => (
                <Link
                  key={orderReference.id}
                  to={`/purchasing/orders/${orderReference.id}`}
                >
                  {orderReference.reference}
                </Link>
              ))}
            </div>
          </div>
        )}
      </MaPanel.Body>
      <OrderRequestSummaryPanelFooter orderRequest={orderRequest} />
    </MaPanel>
  )
}

export default OrderRequestSummaryPanel
