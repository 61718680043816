import { type ReactElement } from 'react'
import { type Notification } from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import NotificationAlert from './NotificationAlert'
import useNotifications from '../hooks/useNotifications'
import TimeAgo from '../../common/components/TimeAgo'

interface NotificationListProps {
  totalCount: number
  notifications: Notification[]
}

function NotificationList ({
  totalCount,
  notifications
}: Readonly<NotificationListProps>): ReactElement {
  const { t } = useTranslation()
  const { removeNotification, markAsRead } = useNotifications()

  const handleClick = (notification: Notification): void => {
    if (!notification.read) {
      void markAsRead(notification.uuid as string)
    }
  }

  if (totalCount < 1) {
    return (
      <>
        <p className="text-center">{t('notifications.empty.part_1')}</p>
        <p className="text-center">{t('notifications.empty.part_2')}</p>
      </>
    )
  }

  if (totalCount > 0 && notifications.length < 1) {
    return <p className="text-center">{t('notifications.empty.filter')}</p>
  }

  return (
    <>
      {notifications.map((notification: Notification) => (
        <NotificationAlert
          expandable={true}
          key={notification.uuid}
          unread={!notification.read}
          title={notification.subject}
          body={notification.body}
          footer={<TimeAgo date={notification.creation_date ?? ''} />}
          onClick={() => {
            handleClick(notification)
          }}
          onClose={() => {
            void removeNotification(notification.uuid as string)
          }}
        />
      ))}
    </>
  )
}

export default NotificationList
