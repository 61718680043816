import type { ActionState } from '@amici/myamici-workflow-client'
import { type ReactElement, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'

export interface ActionStateEditorProps {
  state: ActionState
  onAssignmentChange: (assignment: string) => void
}

function ActionStateEditor ({
  state,
  onAssignmentChange
}: Readonly<ActionStateEditorProps>): ReactElement {
  const id = useId()
  const { t } = useTranslation()
  return (
    <Form.Group>
      <Form.Label id={id}>{t('workflows.action.assignment')}</Form.Label>
      <Form.Control
        aria-labelledby={id}
        value={state.assignment_group}
        onChange={event => {
          onAssignmentChange(event.target.value)
        }}
      />
    </Form.Group>
  )
}

export default ActionStateEditor
