import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import { type OrderRequest } from '@amici/myamici-api-client'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'
import useOrderRequestWorkflow from '../hooks/useOrderRequestWorkflow'
import useOrderRequest from '../hooks/useOrderRequest'
import useWorkflowTasks from '../../workflow/hooks/useWorkflowTasks'

interface OrderRequestCancelModalProps {
  show: boolean
  orderRequest: OrderRequest
  workflowExecutionId?: string
  onClose: () => void
}

function OrderRequestCancelModal ({
  show,
  orderRequest,
  workflowExecutionId,
  onClose
}: Readonly<OrderRequestCancelModalProps>): ReactElement {
  const { t } = useTranslation()
  const { cancel: cancelWorkflow, isCancelling: isCancellingWorkflow } =
    useOrderRequestWorkflow(orderRequest.id)
  const {
    cancel,
    isCancelling,
    mutate: refreshOrderRequest
  } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)
  const { showToastMessage } = useToastNotification()
  const { mutate: refreshTasks } = useWorkflowTasks({})

  const { trackEvent } = useOrderRequestTrackingEvent(orderRequest.id)

  const handleCancel = (reason: string): void => {
    trackEvent('cancel')
    const onCancelError = (): void => {
      showToastMessage('danger', t('order_request.cancel.error'))
    }
    if (workflowExecutionId) {
      void cancelWorkflow(workflowExecutionId, reason, true)
        .then(() => {
          void refreshHistory()
          void refreshOrderRequest()
          void refreshTasks()
        })
        .catch(onCancelError)
    } else {
      void cancel(reason)
        .then(() => {
          void refreshHistory()
        })
        .catch(onCancelError)
    }
    onClose()
  }

  return (
    <OrderRequestStatusUpdateModal
      show={show}
      onClose={onClose}
      onConfirm={handleCancel}
      disabled={isCancelling || isCancellingWorkflow}
      confirmLabel={t('order_request.action.cancel')}
      title={t('order_request.cancel_form.title', {
        ref: orderRequest.order_ref
      })}
      reasonLabel={t('order_request.form.label.cancel_reason')}
      reasonRequired={true}
    />
  )
}

export default OrderRequestCancelModal
