import { type ComponentProps, type ReactNode } from 'react'
import MaTooltip from '../../common/components/MaTooltip'
import styles from '../assets/scss/OrderLineItemTooltip.module.scss'
import { BsJournalText } from 'react-icons/bs'
import classNames from 'classnames'

function OrderLineItemNoteTooltip ({
  note,
  className
}: Readonly<{ note?: string | null } & ComponentProps<'span'>>): ReactNode {
  if (!note?.trim()) {
    return null
  }

  return (
    <MaTooltip
      placement={'bottom'}
      trigger={['hover', 'click']}
      text={
        <div className={styles.text}>
          <p>{note}</p>
        </div>
      }
      className={classNames(styles.notes, className)}
    >
      <BsJournalText size={16} role="button" />
    </MaTooltip>
  )
}

export default OrderLineItemNoteTooltip
