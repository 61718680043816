import useApi from '../../common/hooks/useApi'
import useSWR from 'swr'
import useAccounts from '../../common/hooks/useAccounts'
import { type Group, type GroupsPage } from '@amici/myamici-workflow-client'

interface UseWorkflowGroupsHook {
  groups: Group[] | undefined
  isLoading: boolean
}

function useWorkflowGroups (): UseWorkflowGroupsHook {
  const {
    groupsApi: { getGroupsPage },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR<GroupsPage, Error>(
    accountId ? ['workflow-groups', accountId] : null,
    async () =>
      await fetcher(getGroupsPage, {
        accountId
      })
  )

  return {
    groups: data?.content,
    isLoading
  }
}

export default useWorkflowGroups
